const familyRelationships = [
    {
     name: "sister"
    },
    {
     name: "brother"
    },
    {
     name: "father"
    },
    {
     name: "mother"
    },
    {
     name: "daughter"
    },
    {
     name: "son"
    },
    {
     name: "husband/wife"
    },
    {
     name: "grandfather"
    },
    {
    name:"grandmother"
    }
 ];
 export default familyRelationships;