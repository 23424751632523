import React, { useEffect, useState } from "react";
import { TextInput } from "../TextInput/TextInput";
import "./style.css";
import GettingService from "../../services/getting.service";
import { LifeStyleTab } from "../LifeStyleTab/LifeStyleTab";
import TokenService from "../../services/token.service";
import { PatientAddresses } from "../PatientAddresses/PatientAddresses";
import { EmailInput } from "../EmailInput/EmailInput";
import { useTranslation } from "react-i18next";
import { SelectBox } from "../SelectBox/SelectBox";
import { deleteData, editData, postData } from "../../services/apiService";
import { SignInUpButton } from "../SignInUpButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bloodTypes from "../../shares/bloodTypes";
import { SelectMultiItems } from "../SelectMultiItems/SelectMultiItems";
import { PhoneNumberInput } from "../PhoneNumberInput";
import validator from "validator";
import { InsuranceInfo } from "../InsuranceInfo/InsuranceInfo";
import { DateInput } from "../DateInput/DateInput";

export const SettingsTab = ({
  setLoadingFlag,
  userData,
  addresses,
  setAddresses
}) => {
  const [activeSetting, setActiveSetting] = useState("account");
  const user = TokenService.getUser();
  const { i18n, t } = useTranslation();
  const [firstName, setFirstName] = useState(user?.firstname);
  const [lastName, setLastName] = useState(user?.lastname);
  const [phone, setPhone] = useState(user?.phone);
  const [email, setEmail] = useState(userData?.email ? userData?.email : '');
  const [gender, setGender] = useState(user?.profile?.gender);
  const [height, setHeight] = useState(user?.profile?.patient?.height);
  const [weight, setWeight] = useState(user?.profile?.patient?.weight);
  const [birthdate, setBirthDate] = useState(user?.profile?.birthdate)
  const [bloodType, setBloodType] = useState(user?.profile?.patient?.bloodType);
  const [allergies, setAllergies] = useState([]);
  const [vaccines, setVaccines] = useState([]);
  const [familyHistory, setFamilyHistory] = useState([]);
  const [insurances, setInsurances] = useState(
    user?.profile?.patient?.patient_insurances
  );
  const [chronicDiseases, setChronicDiseases] = useState([]);
  const [Surgeries, setSurgeries] = useState([]);
  const [lifeStyles, setLifeStyles] = useState([]);
  const [selectedAllergy, setSelectedAllergy] = useState([]);
  const [selectedVaccine, setSelectedVaccine] = useState([]);
  const [selectedFamilyHistory, setSelectedFamilyHistory] = useState([]);
  const [selectedInsurance, setSelectedInsurance] = useState([
    user?.profile?.patient?.patient_insurances[user?.profile?.patient?.patient_insurances?.length - 1]?.insurance?.id
  ]);
  const [insuranceFile, setInsuranceFile] = useState(
    user?.profile?.patient?.patient_insurances[user?.profile?.patient?.patient_insurances?.length - 1]?.insuranceFile?.id
  );
  const [selectedLifestyles, setSelectedLifestyles] = useState([]);
  const [selectedChronicDisease, setSelectedChronicDisease] = useState([]);
  const [selectedSurgery, setSelectedSurgery] = useState([]);
  const [editable, setEditable] = useState(false);
  const [profile, setProfile] = useState({});
  const [errors, setErrors] = useState({});
  const [insuranceId, setInsuranceId] = useState();
  const [insuranceImage, setInsuranceImage] = useState(
    user?.profile?.patient?.patient_insurances[user?.profile?.patient?.patient_insurances?.length - 1]?.insuranceFile?.url
  );

  async function getNeededData() {
    await GettingService.getAllergies(setAllergies, setLoadingFlag);
    await GettingService.getVaccines(setVaccines, setLoadingFlag);
    await GettingService.getFamilyHistory(setFamilyHistory, setLoadingFlag);
    await GettingService.getInsurances(setInsurances, setLoadingFlag);
    await GettingService.getChronicDiseases(setChronicDiseases, setLoadingFlag);
    await GettingService.getSurgeries(setSurgeries, setLoadingFlag);
    await GettingService.getLifeStyles(setLifeStyles, setLoadingFlag);
    await GettingService.getProfile(
      user?.profile?.patient?.id,
      setSelectedAllergy,
      setSelectedSurgery,
      setSelectedVaccine,
      setSelectedChronicDisease,
      setSelectedLifestyles,
      setInsuranceId,
      setLoadingFlag
    );
  }

  function addAddress(item, city, editFlag, index) {
    const arr = addresses;
    let newObj = item?.data?.attributes;
    newObj.id = item?.data?.id;
    newObj.cities = [city[0]?.attributes];
    if (editFlag) {
      let newData = [...arr];
      newData.splice(index, 1, newObj);
      newData.unshift(newObj); // Put the item at the first index of the array
      setAddresses(newData);
    } else {
      arr.unshift(newObj);
      setAddresses(arr);
    }

    setEditable(true);
  }

  function addItem(item, items, setItem) {
    const arr = items;
    arr.unshift(item);
    setItem(arr);
  }

  function deleteAddress(address) {
    deleteData("/addresses", address?.id).then((response) => {
      if (response) {
        const arr = addresses.filter((q) => q.id !== address?.id);
        setAddresses(arr);
      }
      setEditable(true);
    });
  }

  async function EditPatient() {
    let errorsObj = {};
    setLoadingFlag(true);
    if (email && !validator.isEmail(email)) {
      errorsObj.email = t("email.error");
    }

    if (phone && !validator.isMobilePhone(phone)) {
      errorsObj.phone = t("phone.validation.error");
    }

    const insurance_data = {
      patient: user?.profile?.patient?.id,
      insurance: selectedInsurance?.length ? selectedInsurance[0] : null,
      insuranceFile: insuranceFile ? insuranceFile : null,
    };

    const data = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      whatsappNumber: phone,
      gender: gender,
      birthdate,
      weight: weight,
      height: height,
      bloodType: bloodType,
      type: "patient",
    };

    const data_relative = {
      allergies: selectedAllergy.map(function (e) {
        return e.value;
      }),
      vaccines: selectedVaccine.map(function (e) {
        return e.value;
      }),
      addresses: addresses.map(function (e) {
        return e.id;
      }),
      chronic_diseases: selectedChronicDisease.map(function (e) {
        return e.value;
      }),
      surgeries: selectedSurgery.map(function (e) {
        return e.value;
      }),
      // patient_lifestyles: selectedLifestyles,
    };

    if(insuranceId){
      await editData("/patient-insurances", insuranceId , {data: insurance_data});
    }else{
      await postData("/patient-insurances", {data: insurance_data}, true).then((res)=>{
        setInsuranceId(res?.data?.id);
      });
    }

    if (!Object.keys(errorsObj).length) {
      await editData("/users", userData?.id, data).then(async (response) => {
        if (response) {
          await editData(`/patients`, user?.profile?.patient?.id, {
            data: data_relative,
          }).then((response) => {
            setLoadingFlag(false);
            toast("Your data was successfully saved");
          });
        }
      });
    } else {
      setLoadingFlag(false);
    }

    setErrors(errorsObj);
  }

  function setInsurance(e){
    setSelectedInsurance([e])
  }

  useEffect(() => {
    getNeededData();
  }, []);

  return (
    <div className="d-flex flex-column ms-5 dashboard-home-container">
      <ToastContainer />
      <form
        onChange={() => setEditable(true)}
        onSubmit={(event) => event.preventDefault()}
      >
        <div className="settings-tab mx-4">
          <div
            className={`settings-li pointer ${
              activeSetting === "account" ? "selected-setting" : ""
            }`}
            onClick={() => setActiveSetting("account")}
          >
            {t("dashboard.accountdetails")}
          </div>
          <div
            className={`settings-li pointer ${
              activeSetting === "medical" ? "selected-setting" : ""
            }`}
            onClick={() => setActiveSetting("medical")}
          >
            {t("dashboard.medicalinformation")}
          </div>
          <div
            className={`settings-li pointer ${
              activeSetting === "insurance" ? "selected-setting" : ""
            }`}
            onClick={() => setActiveSetting("insurance")}
          >
            {t("dashboard.insurance")}
          </div>
          <div
            className={`settings-li pointer ${
              activeSetting === "region" ? "selected-setting" : ""
            }`}
            onClick={() => setActiveSetting("region")}
          >
            {t("addresses")}
          </div>
          <div>
            <SignInUpButton
              state={editable ? "default" : "greyed-out"}
              className={"book-button py-4"}
              name={t("savechanges")}
              onClick={() => EditPatient()}
            />
          </div>
        </div>
        {activeSetting === "account" && (
          <div className="mt-4 d-flex flex-wrap gap-4 settings-inputs-container m-auto">
            <div className="settings-input d-flex flex-column ">
              <label className="settings-input-label py-2 ">
                {t("auth.firstname")}
              </label>
              <TextInput
                value={firstName}
                placeholder={t("enter.your.firstname")}
                onChange={setFirstName}
              />
            </div>
            <div className="settings-input d-flex flex-column ">
              <label className="settings-input-label py-2 ">
                {t("auth.lastname")}
              </label>
              <TextInput
                value={lastName}
                placeholder={t("enter.your.lastname")}
                onChange={setLastName}
              />
            </div>
            <div className="settings-input d-flex flex-column ">
              <label className="settings-input-label py-2 ">
                {t("auth.email")}
              </label>
              <EmailInput
                value={email}
                placeholder={t("enter.email")}
                onChange={setEmail}
                noMargin={true}
                error={errors?.email}
              />
            </div>
            <div className="settings-input d-flex flex-column">
              <label className="settings-input-label py-2 ">
                {t("phonenumber")}
              </label>
              <PhoneNumberInput
                className="email-input-instance"
                property1="empty"
                defaultValue={phone}
                onChange={setPhone}
                placeholder={t("enter.your.phone")}
                error={errors?.phone}
                noMargin={true}
              />
            </div>
            <div className="settings-input d-flex flex-column">
              <label className="settings-input-label py-2 ">
                {t("gender")}
              </label>
              <SelectBox
                items={[
                  {
                    name: "Male",
                  },
                  { name: "Female" },
                ]}
                placeholder={t("select.gender")}
                onChange={setGender}
                divClass={"email-input select-padding"}
                formClass="border-0 form-select"
                value={gender}
              />
            </div>
            <div className="settings-input d-flex flex-column">
              <label className="settings-input-label py-2 ">
                {t("birthdate")}
              </label>
              <DateInput
                property1="empty"
                placeholder={t("select.birthdate")}
                onChange={setBirthDate}
                error={errors?.date}
                divClass={"email-input select-padding"}
                value={birthdate}
                formClass="border-0 form-select"
                maxDate={new Date()?.toISOString()?.split("T")[0]}
              />
            </div>
          </div>
        )}
        {activeSetting === "medical" && (
          <div className="mt-4 d-flex flex-wrap gap-4 settings-inputs-container m-auto  ">
            <div className="settings-input d-flex flex-column  ">
              <label className="settings-input-label py-2 ">
                {t("dashboard.weightlabel")}
              </label>
              <TextInput
                value={weight}
                placeholder={t("enter.your.weight")}
                onChange={setWeight}
              />
            </div>
            <div className="settings-input d-flex flex-column  ">
              <label className="settings-input-label py-2 ">
                {t("dashboard.heightlabel")}
              </label>
              <TextInput
                value={height}
                placeholder={t("enter.your.height")}
                onChange={setHeight}
              />
            </div>
            <div className="settings-input d-flex flex-column  ">
              <label className="settings-input-label py-2 ">
                {t("dashboard.bloodtype")}
              </label>
              <SelectBox
                value={bloodType}
                placeholder={t("enter.your.bloodtype")}
                onChange={setBloodType}
                items={bloodTypes ? bloodTypes : []}
                divClass={"email-input select-padding"}
                formClass="border-0 form-select"
              />
            </div>
            <SelectMultiItems
              title={t("dashboard.allergies")}
              items={allergies}
              selectedItems={selectedAllergy}
              addToItems={addItem}
              onChange={setSelectedAllergy}
              postUrl={"/allergies"}
              setItems={setAllergies}
              setLoadingFlag={setLoadingFlag}
            />
            <SelectMultiItems
              title={t("dashboard.vaccines")}
              items={vaccines}
              selectedItems={selectedVaccine}
              addToItems={addItem}
              onChange={setSelectedVaccine}
              postUrl={"/vaccines"}
              setItems={setVaccines}
              setLoadingFlag={setLoadingFlag}
            />
            <SelectMultiItems
              title={t("dashboard.chronicdiseases")}
              items={chronicDiseases}
              selectedItems={selectedChronicDisease}
              addToItems={addItem}
              onChange={setSelectedChronicDisease}
              postUrl={"/chronic-diseases"}
              setItems={setChronicDiseases}
              setLoadingFlag={setLoadingFlag}
            />
            <SelectMultiItems
              title={t("dashboard.surgeries")}
              items={Surgeries}
              selectedItems={selectedSurgery}
              addToItems={addItem}
              onChange={setSelectedSurgery}
              postUrl={"/surgeries"}
              setItems={setSurgeries}
              setLoadingFlag={setLoadingFlag}
            />
          </div>
        )}
        {activeSetting === "region" && (
          <PatientAddresses
            addresses={addresses}
            setLoadingFlag={setLoadingFlag}
            addAddress={addAddress}
            deleteAddress={deleteAddress}
          />
        )}
        {activeSetting === "insurance" && (
          <InsuranceInfo
            insurances={insurances}
            setInsurance={setInsurance}
            selectedInsurances={selectedInsurance}
            setInsuranceFile={setInsuranceFile}  
            setInsuranceImage= {setInsuranceImage}
            insuranceImage={insuranceImage}
          />
        )}
      </form>
    </div>
  );
};
