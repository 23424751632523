export const getCountryIp = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    try {
      const response = await fetch(
        `https://ipinfo.io/${data?.ip}?token=7adb4705dac038`
      );
      const dataCountry = await response.json();
      localStorage.setItem('current_country', dataCountry?.country);
    } catch (error) {
      console.error("Error fetching geolocation data:", error);
    }
  } catch (error) {
    console.error("Error fetching IP address:", error);
  }
};
