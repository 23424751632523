import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import GettingService from "../../services/getting.service";
import { deleteData, getData } from "../../services/apiService";
import { toast } from "react-toastify";

export const LoyaltyPoints = ({ setLoadingFlag }) => {
  const { i18n, t } = useTranslation();
  const [pointsHistory, setPointsHistory] = useState([]);
  const [currentItem, setCurrentItem] = useState({});

  function getPointsHistory() {
    getData("/userPoints", null, true).then((res)=>{
        setPointsHistory(res.data);
    });
  }

  useEffect(() => {
    getPointsHistory();
  }, []);

  return (
    <div className="mt-4 d-flex flex-wrap gap-4 settings-inputs-container m-auto  ">
      <table
        className="mt-3 width100per "
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
      >
        <thead className="">
          <tr className="head-family-table">
            <th className="p-2">#</th>
            <th className="p-2">{t("serviceType")}</th>
            <th className="p-2">{t("date")}</th>
            <th className="p-2"></th>
          </tr>
        </thead>
        <tbody>
          {pointsHistory?.map((item, key) => (
            <tr key={`${key}-item`}>
              <td className="clinic p-2 px-4">{item?.doxxid}</td>
              <td className="item-width clinic p-2">{item?.servicetype}</td>
              <td className="clinic p-2">{item?.date}</td>
              <td className="clinic p-2">{`${item?.type === 'history' ? t("youRedeemed") : t("youEarned")} ${item?.usedpoints} ${t("points")}` }</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
