import { t } from "i18next";
import { useState } from "react";
import { useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";

export const MultipleSelect = ({
  options,
  selected,
  setSelected,
  divClass,
  className,
  error,
}) => {
  const customValueRenderer = (selected, _options) => {
    return selected?.length
      ? selected.map(({ label }) => "✔️ " + label)
      : t("NoItemsSelected");
  };
  const [multiOptions, setMultiOptions] = useState([]);
  const filterOptions = (_options, filter) => {
    if (!filter) {
      return _options;
    }
    const re = new RegExp(filter, "i");
    return _options.filter((opt) => opt && opt?.label?.match(re));
  }
  useEffect(() => {
    const currentOptions = [];
    options.forEach((element) => {
      currentOptions.push({
        label: element?.attributes?.name,
        value: element?.id,
      });
    });
    setMultiOptions(currentOptions);
  }, [setMultiOptions]);
  return (
    <div className={`${divClass}`}>
      <MultiSelect
        options={multiOptions}
        value={selected}
        onChange={setSelected}
        labelledBy="Select"
        valueRenderer={customValueRenderer}
        className={`email-input ${className}`}
        filterOptions={filterOptions}
      />
      <p className="text-danger  h6 position-absolute  text-start">{error}</p>
    </div>
  );
};
