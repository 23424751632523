import "./style.css";

export const Loading = ({ display }) => {
  return (
    <>
      <div className={`lmask ${display}`}>
        <img  loading="lazy" className="loading-img" alt="loading" src="./img/loading.gif" />
      </div>
    </>
  );
};
