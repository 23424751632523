import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import "../BookingModal/style.css";
import "react-calendar/dist/Calendar.css";
import { SignInUpButton } from "../SignInUpButton/SignInUpButton";
import { TextInput } from "../TextInput";
import { PhoneNumberInput } from "../PhoneNumberInput";
import './style.css'
import { confirmAlert } from "react-confirm-alert";

export const AddFamilyMember = ({
  modalOpen,
  setFirstName,
  setLastName,
  setPhoneNumber,
  addFamilyMember,
  firstName,
  lastName,
  phoneNumber,
  errors,
  setModalOpen
}) => {
  const { t } = useTranslation();
  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      background: "#ffffff",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "67%",
      height: "fit-content",
      maxWidth: "700px",
      top: "3rem",
      margin: "auto",
      zIndex: 1
    },
    overlay: {
      position: "fixed",
      zIndex: 3,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };

  function submit (){
    confirmAlert({
      title: "",
      message:
        t("addmember.warning"),
      buttons: [
        {
          label: t("proceed"),
          onClick: () => {
            addFamilyMember();
          },
        },
        {
          label: t("cancel"),
          onClick: () => {},
        },
      ],
    });
  }
  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel="AddingFamilyMember"
      style={customStyles}
      ariaHideApp={false}
    >
       <img  loading="lazy"
          src="./img/close.svg"
          className="family-member-close-img"
          onClick={() => {
            setModalOpen(false);
            setFirstName('');
            setLastName('');
            setPhoneNumber('');
          }}
        />
      <div className="add-family-font-style add-family-head">
        {t("addfamilymember")}
      </div>
      <div className="add-family-font-style sub-add-family">
        {t("subaddfamily")}
      </div>
      <div>
        <label className="add-family-font-style title-label-family">
          {t("auth.firstname")}
        </label>
        <TextInput
          placeholder={`${t("auth.firstname")}`}
          onChange={setFirstName}
          className={'width100per'}
        />
      </div>
      <div>
        <label className="add-family-font-style title-label-family">
          {t("auth.lastname")}
        </label>
        <TextInput
          placeholder={`${t("auth.lastname")}`}
          onChange={setLastName}
          className={'width100per'}
        />
      </div>
      <div>
        <label className="add-family-font-style title-label-family">
          {t("phonenumber")}
        </label>
        <PhoneNumberInput
          className="email-input-instance width100per"
          property1="empty"
          onChange={setPhoneNumber}
          error={errors?.phone || errors?.general}
          placeholder={t("auth.enterphone")}
        />
      </div>
      <SignInUpButton
        state={
            !firstName || !lastName || !phoneNumber 
            ? "greyed-out"
            : "default"
        }
        className={"add-member-btn"}
        name={t("addmember")}
        onClick={() => submit()}
      />
    </ReactModal>
  );
};
