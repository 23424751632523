import { useTranslation } from "react-i18next";
import { SelectBox } from "../SelectBox/SelectBox";
import { FileUploader } from "react-drag-drop-files";
import PostingService from "../../services/posting.service";
import { useEffect } from "react";

export const InsuranceInfo = ({
 insurances,
 setInsurance,
 selectedInsurances,
 setInsuranceFile,
 setInsuranceImage,
 insuranceImage
}) => {
  const handleChange = (submittedFiles) => {
    let selectedFiles = [];
    
    PostingService?.uploadFile([submittedFiles]).then((res)=>{
      res.forEach((element) => {
        setInsuranceFile(element?.id);
        setInsuranceImage(element?.url)
      });
    })
  };

  const { i18n, t } = useTranslation();

  return (
    <div className="mt-4 d-flex flex-wrap gap-4 settings-inputs-container m-auto  ">
      <div className="settings-input d-flex flex-column  ">
        <label className="settings-input-label py-2 ">
          {t("dashboard.insurance")}
        </label>
        <SelectBox
          items={insurances}
          placeholder={t("select.insurance")}
          onChange={setInsurance}
          divClass={"email-input select-padding"}
          formClass="border-0 form-select"
          value={selectedInsurances?.length ? selectedInsurances[selectedInsurances?.length - 1] : null}
          valueIsId={true}
          type={"insurance"}
        />
      </div>
      <div className="settings-input d-flex flex-column  ">
        <label className="settings-input-label py-2 ">
          {t("dashboard.insuranceCard")}
        </label>
        <FileUploader
          handleChange={handleChange}
          name="file"
          classes={"width100per mw-100 mt-2"}
        />
        <img  loading="lazy" src={insuranceImage} alt="insurance-img" className="doctor-img-ask position-relative mt-3"/>       
      </div>    
    </div>
  );
};
