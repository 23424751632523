import { postData } from "./apiService";

const addItem = async (url, name, setLoadingFlag, setItems) => {
  setLoadingFlag(true);
  await postData(
    url,
    {
      data: {
        name,
      },
    },
    true
  )
    .then((response) => {
      setLoadingFlag(false);
      setItems(response?.data);
      return response.data
    })
    .catch(() => setLoadingFlag(false));
};

const addFamilyMemberToPlan = async (setLoadingFlag, data, setModalOpen, setErrors) => {
  setLoadingFlag(true);
  await postData("/supscription-plan/familySubscription", data, true)
    .then((response) => {
      setModalOpen(false);
      setLoadingFlag(false);
      return response.data;
    })
    .catch((error) => {
     throw error
    });
};

const uploadFile = async(files, caption)=>{
  const formData = new FormData();
  Array.from(files)?.forEach(item => {
    formData.append("files", item);
    formData.append("caption", caption);
  });

  return await postData(
    '/upload', formData, true, true
  )
}

const PostingService = {
  addItem,
  addFamilyMemberToPlan,
  uploadFile
};

export default PostingService;
