import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { getData } from "../../services/apiService";
import { useTranslation } from "react-i18next";

export const MetaTags = ({ type, setContent }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [item, setItem] = useState();
  const [image, setImage]= useState('');
  const {i18n} = useTranslation();
  
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "headline": item?.attributes?.h1,
    "description": item?.attributes?.descrption,
    "keywords": item?.attributes?.descrption,
    "image": item?.attributes?.tags,
    "url": document.URL
  };

  useEffect(()=>{
    getData("/meta-tags", null, null, true).then((res) => {
      let current_item = res?.data?.find(item => item?.attributes?.slug === type && ((i18n?.language === item?.attributes?.type || (i18n?.language === 'en' && !item?.attributes?.type ))))
      setItem(current_item);
      if(setContent){
        setContent(current_item?.attributes?.data);
      }
      
    }
      );

  },[])

  return (
    <Helmet>
      <title>{item?.attributes?.title}</title>
      <meta name="title" content={item?.attributes?.title} />
      <meta name="description" content={item?.attributes?.descrption} />
      <meta name="keywords" content={item?.attributes?.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={document?.URL} />
      <meta property="og:title" content={item?.attributes?.title} />
      <meta property="og:description" content={item?.attributes?.descrption} />
      <meta property="og:image" content={item?.attributes?.tags} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={document.URL} />
      <meta property="twitter:title" content={item?.attributes?.title} />
      <meta property="twitter:description" content={item?.attributes?.descrption} />
      <meta property="twitter:image" content={item?.attributes?.tags} />
      <link rel="canonical" href={document.URL} />
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};
