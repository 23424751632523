import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export const AppDownload = ({}) => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "headline": "DNC Download app - DOXX",
        "description":"DNC Download app - DOXX",
        "keywords": "DNC Download app - DOXX",
        "image": "DNC Download app - DOXX",
        "url": "https://www.doxx.care/app-download"
      };
    useEffect(() => {
        // Define the URLs for your app on the Play Store and App Store
        const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.openner.doxx_patient&hl=en&gl=US';
        const appStoreUrl = 'https://apps.apple.com/us/app/doxx-patient/id1579305823';
    
        // Detect if the device is iOS or Android
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
          // Redirect to Google Play Store for Android
          window.location.href = playStoreUrl;
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          // Redirect to Apple App Store for iOS
          window.location.href = appStoreUrl;
        } else {
          // If on desktop or other device, you can redirect to your website or show a message
          console.log('Please visit the store on a mobile device.');
        }
    },[])
  return (
    <div className="mt-3">
         <Helmet>
      <title>DNC Download app - DOXX</title>
      <meta name="title" content="DNC Download app - DOXX" />
      <meta name="description" content="DNC Download app - DOXX" />
      <meta name="keywords" content="DNC Download app - DOXX" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.doxx.care/app-download" />
      <meta property="og:title" content="DNC Download app - DOXX" />
      <meta property="og:description" content="DNC Download app - DOXX" />
      <meta property="og:image" content="DNC Download app - DOXX" />
      <meta property="twitter:card" content="DNC Download app" />
      <meta property="twitter:url" content="https://www.doxx.care/app-download" />
      <meta property="twitter:title" content="DNC Download app - DOXX" />
      <meta property="twitter:description" content="DNC Download app - DOXX" />
      <meta property="twitter:image" content="DNC Download app - DOXX" />
      <link rel="canonical" href="DNC Download app - DOXX" />
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
        <div className="my-4">Redirecting you to the download the app...</div>
    </div> 
  );
};
