import { FilterSearch } from "../../components/FilterSearch/FilterSearch";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GettingService from "../../services/getting.service";
import { LabCard } from "../../components/LabCard/LabCard";
import ReactPaginate from "react-paginate";
import { ConvertToArabicNumbers } from "../../helpers/convertToArabic";
import { MetaTags } from "../../components/MetaTags/MetaTags";

const ScanLabList = ({ setLoadingFlag, type }) => {
  const { state } = useLocation();
  const { t, i18n } = useTranslation();
  const [searchName, setSearchName] = useState(state?.searchWord);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(8);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [supplierId, setSupplierId] = useState(state?.supplierId);
  const [suppliers, setSuppliers] = useState([]);
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [content, setContent] = useState();

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  const search = useCallback(async () => {
    let params = {
      "populate[supplier]": "*",
      "filters[supplier][type]": type,
      "pagination[page]": offset,
      "pagination[pageSize]": limit,
      "sort[numberOfPurchases]": "Desc",
      locale: localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "en",
      "coordinates[lat]": position?.latitude,
      "coordinates[long]": position?.longitude,
    };
    if (supplierId) {
      params = Object.assign(params, {
        "filters[supplier][id][$eq]": supplierId,
      });
    }

    params = Object.assign(params, {
      "filters[$or][0][name][$contains]": searchName ? searchName : null,
      "filters[$or][1][name_ar][$contains]": searchName ? searchName : null,
    });

    await GettingService.getProductsBySupplier(
      setProducts,
      setTotal,
      params,
      setLoadingFlag
    );
  }, [setLoadingFlag, searchName, supplierId, offset]);

  useEffect(() => {
    search();
  }, [search, i18n.language, position?.latitude, position?.longitude]);

  useEffect(() => {
    GettingService.getSuppliers(setSuppliers, type, setLoadingFlag);
  }, []);

  useEffect(() => {
    setOffset(1);
  }, [searchName, supplierId]);

  return (
    <>
      <MetaTags
        setContent={setContent}
        type={type === "scan" ? "scans page" : "labs page"}
        title={`${t("doxx")} - ${t(
          type === "scan" ? "plan.feature.scan" : "plan.feature.lab"
        )}`}
        description={`${t("scanlab.title")} ${t("scanlab.title2")}`}
      />
      <div className="home-visit-div d-flex">
        <FilterSearch
          setLoadingFlag={setLoadingFlag}
          setSearchName={setSearchName}
          searchName={searchName}
          selectedSupplierId={supplierId}
          setSupplierId={setSupplierId}
          search={search}
          type="scan-lab"
          suppliers={suppliers}
          total={total ? total : 0}
        />
        <div className="d-flex flex-column cards-doctors-div">
          <div className="d-flex gap-3 m-auto labs-container-slider">
            {suppliers?.map((item, key) => (
              <div
                className="service-provider-button width-fit pointer provider-sm-div"
                onClick={() => setSupplierId(item.id)}
                key={`provider-${key}`}
              >
                <div className="provider-content mt-3 px-3">
                  {item?.attributes?.profile?.data?.attributes?.imageUrl ? (
                    <img
                      loading="lazy"
                      src={
                        item?.attributes?.profile?.data?.attributes?.imageUrl
                      }
                      alt="scan-img"
                      className="sm-scan-img"
                    />
                  ) : (
                    <p>
                      {i18n.language === "ar" && item?.attributes?.name_ar
                        ? item?.attributes?.name_ar
                        : item?.attributes?.name}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex flex-column mt-4">
            <div className="doctor-cards mb-4 pt-3">
              {products?.map((item, key) => (
                <LabCard
                  key={`nurse-card-${key}`}
                  name={item?.attributes?.name}
                  price={item?.attributes?.price}
                  name_ar={item?.attributes?.name_ar}
                  id={item?.id}
                  type={item?.attributes?.supplier?.data?.attributes?.type}
                />
              ))}
              <div className="pagination-container">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={t("pagination.next")}
                  onPageChange={(e) => {
                    setOffset(e.selected + 1);
                  }}
                  pageRangeDisplayed={limit}
                  pageCount={Math.ceil(total / limit)}
                  previousLabel={t("pagination.prev")}
                  renderOnZeroPageCount={null}
                  disabledClassName="greyed-out"
                  pageLabelBuilder={(p) => ConvertToArabicNumbers(p)}
                />
              </div>
              <div className="for-less-than-container  mt-3 text-center">
                <div className="for-less-than data-metatags black-text">{content}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScanLabList;
