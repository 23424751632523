import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

export const SelectBox = ({
  placeholder,
  items,
  type,
  onChange,
  divClass,
  formClass,
  value,
  valueIsId,
  disablePlaceholder,
}) => {
  const { i18n } = useTranslation();
  return (
    <div className={`${divClass}`}>
      <select
        className={`${formClass}`}
        onChange={(e) => onChange(e?.target?.value)}
        value={value !== null ? value : ""}
      >
        <option
          className="disabled-option"
          value=""
          disabled={disablePlaceholder}
        >
          {placeholder}
        </option>
        {items?.map((item, key) => (
          <option
            item={item}
            value={
              valueIsId
                ? item?.id
                : type === "region"
                ? item?.attributes?.name : 
                type === "doctorgrade" ? item?.attributes?.title 
                : item.name?.toLowerCase()
            }
            key={`${key}-${type}`}
          >
            {type === "region"
              ? item?.attributes?.name
              : type === "address"
              ? item.street
              : type === "supplier"
              ? i18n.language === "ar" && item?.attributes?.name_ar
                ? item?.attributes?.name_ar
                : item?.attributes?.name
              : type === "insurance"
              ? item?.attributes?.name
              : type === "doctorgrade" && i18n.language === "ar" && item?.attributes?.title_ar ? item?.attributes?.title_ar 
              : type === "doctorgrade" && i18n.language === "en" ? item?.attributes?.title
              : t(item?.name)}
          </option>
        ))}
      </select>
    </div>
  );
};
