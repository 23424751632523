import React, { useEffect, useState } from "react";
import { SignInUpButton } from "../../components/SignInUpButton";
import ReactModal from "react-modal";
import { TextInput } from "../../components/TextInput";
import { SelectBox } from "../../components/SelectBox/SelectBox";
import { editData, getData, postData } from "../../services/apiService";
import { TextEditor } from "../../components/TextEditor/TextEditor";
import { FileUploader } from "react-drag-drop-files";
import PostingService from "../../services/posting.service";

export const BlogForm = ({ setLoadingFlag, modalOpen, setModalOpen, id }) => {
  const [arabicBody, setArabicBody] = useState("");
  const [englishBody, setEnglishBody] = useState("");
  const [locale, setLocale] = useState("en");
  const [arabicTitle, setArabicTitle] = useState("");
  const [englishTitle, setEnglishTitle] = useState("");
  const [image, setImage] = useState("");

  const handleChange = (submittedFiles) => {
    let selectedFiles = [];
    PostingService?.uploadFile([submittedFiles]).then((res) => {
      res.forEach((element) => {
        selectedFiles.push(element?.id);
      });
      setImage(selectedFiles);
    });
  };

  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      background: "#ffffff",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "67%",
      height: "fit-content",
      maxWidth: "700px",
      top: "3rem",
      margin: "auto",
      zIndex: 1,
    },
    overlay: {
      position: "fixed",
      zIndex: 3,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };

  useEffect(() => {
    if (id) {
      getData(`/blogs/${id}`, { locales: locale }, true, true).then((res) => {
        if (locale === "en") {
          setEnglishTitle(res?.data?.attributes?.title);
          setEnglishBody(res?.data?.attributes?.body);
        } else {
          setArabicTitle(res?.data?.attributes?.title);
          setArabicBody(res?.data?.attributes?.body);
        }
      });
    }
  }, [id, locale]);

  function submit() {
    setLoadingFlag(true);
    const data = {
      title: englishTitle,
      body: englishBody,
      image: image ? image : null,
    };

    const localizations = {
        locale: "ar",
        title: arabicTitle,
        body: arabicBody
        }

    if (id) {
      editData("/blogs", `${id}`, { data }, true).then((res) => {
        if(res?.data?.id){
          editData("/blogs", `${res?.data?.id}/localizations`, localizations, true).then((locRes) => {
            setModalOpen(false);
            window.location.reload();
          })
        }
      
      });
    } else {
      postData("/blogs", { data}, true, null, true).then((res) => {
        if(res?.data?.id){
          postData(`/blogs/${res?.data?.id}/localizations`, localizations, true, null, true).then((locRes) => {
            setModalOpen(false);
            window.location.reload();
          })
        }
      });
    }
  }

  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel="AddingMetaTags"
      style={customStyles}
      ariaHideApp={false}
    >
      <img  loading="lazy"
        src="./img/close.svg"
        className="family-member-close-img"
        onClick={() => {
          setModalOpen(false);
        }}
      />
      <div className="add-family-font-style add-family-head">
        {id ? "Edit Blog" : "Add Blog"}
      </div>
      <div className="d-flex gap-3 mt-3 px-3">
        <div
          onClick={() => setLocale("ar")}
          className={`d-flex gap-1 time-slot fit-content ${
            locale === "ar" ? "selected-time-slot" : ""
          } pointer`}
        >
          Arabic
        </div>
        <div
          className={`d-flex gap-1 time-slot fit-content pointer ${
            locale === "en" ? "selected-time-slot" : ""
          }`}
          onClick={() => setLocale("en")}
        >
          English
        </div>
      </div>
      {locale === "en" ? (
        <>
          <div className="mt-3">
            <label className="add-family-font-style title-label-family">
              English Title
            </label>
            <TextInput
              placeholder="Enter screen title"
              onChange={setEnglishTitle}
              className={"width100per"}
              value={englishTitle}
            />
          </div>
          <div className="mt-1">
            <label className="add-family-font-style title-label-family">
              English Body
            </label>
            <TextEditor content={englishBody} onChange={setEnglishBody} />
          </div>
        </>
      ) : (
        <>
          <div className="mt-3">
            <label className="add-family-font-style title-label-family">
              Arabic Title
            </label>
            <TextInput
              placeholder="Enter screen title"
              onChange={setArabicTitle}
              className={"width100per"}
              value={arabicTitle}
            />
          </div>
          <div className="mt-1">
            <label className="add-family-font-style title-label-family">
              Arabic Body
            </label>
            <TextEditor content={arabicBody} onChange={setArabicBody} />
          </div>
        </>
      )}
      <FileUploader
        handleChange={handleChange}
        name="file"
        classes={"width100per mw-100 mt-2"}
      />
      <SignInUpButton
        state={
          !arabicBody || !arabicTitle || !englishTitle || !englishBody
            ? "greyed-out"
            : "default"
        }
        className={"add-member-btn"}
        name="Submit"
        onClick={() => submit()}
      />
    </ReactModal>
  );
};
