import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../../shares/config";
import MapService from "../../services/map.service";
import { useTranslation } from "react-i18next";
import "./style.css";

const Map = ({ setLat, setLng, lat, lng, setStreet, street }) => {
  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [center, setCenter] = useState({ lat: 30.0970063, lng: 31.2992309 }); // Default coordinates for San Francisco
  const [marker, setMarker] = useState(center);
  const { t } = useTranslation();

  const onLoad = (map) => {
    setMap(map);
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMarker({ lat: latitude, lng: longitude });
          map.panTo({ lat: latitude, lng: longitude });
          setLat(latitude);
          setLng(longitude);
        },
        () => {
          alert("Geolocation service failed. Please enable location access.");
        }
      );
    } else {
      alert("Your browser doesn't support geolocation.");
    }
  };

  const onMarkerClick = (event) => {
    setMarker({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setCenter({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setLat(event.latLng.lat());
    setLng(event.latLng.lng());
  };

  useEffect(() => {
    MapService.getStreet(lat, lng, setStreet);
  }, [lat, lng]);

  useEffect(() => {
    MapService.getLatAndLng(street, setLat, setLng, setMarker, setCenter);
  }, [street]);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["places"]}>
      <GoogleMap
        mapContainerStyle={{
          height: "230px",
          width: "100%",
        }}
        center={center}
        zoom={14}
        onLoad={onLoad}
        onClick={(e) => onMarkerClick(e)}
      >
        {marker && (
          <Marker
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={onMarkerClick}
            onDragEnd={onMarkerClick}
          />
        )}
        <button
          className="google-map-button"
          onClick={handleGetCurrentLocation}
        >
          <img  loading="lazy"
            src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png"
            alt="Location Icon"
          />
          <span>{t("getcurrentlocation")}</span>
        </button>
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
