import { useEffect, useState } from "react";
import "./style.css";
import { CartFooter } from "../../components/CartFooter/CartFooter";
import { useTranslation } from "react-i18next";
import { StepsBar } from "../../components/StepsBar/StepsBar";
import { CheckoutCard } from "../../components/CheckoutCard/CheckoutCard";
import BookingService from "../../services/booking.service";
import { SuccessMessage } from "../../components/SuccessMessage/SuccessMessage";
import AuthService from "../../services/auth.service";
import { getData } from "../../services/apiService";
import { AddAddress } from "../../components/AddAddress/AddAddress";
import { formatter } from "../../helpers/currencyFormatter";

const BookingPayment = ({ setLoadingFlag }) => {
  const bookingItems = JSON.parse(localStorage.getItem("appointments")) || [];
  const { t, i18n } = useTranslation();
  const [currentStep, setCurrentStep] = useState(2);
  const [buttonTitle, setButtonTitle] = useState(t("checkout"));
  const [type, setType] = useState("");
  const [currentMethodType, setCurrentMethodType] = useState("");
  const [paymentUrl, setPaymentUrl] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [totalCalculation, setTotalCalculation] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [addresses, setUserAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [successOrder, setSuccessOrder] = useState(false);
  const [modalAddress, setModalAddress] = useState(false);
  const [regions, setRegions] = useState(false);
  const [points, setPoints] = useState('');

  function getTotalCalculation(selectedType, removePromoFlag, removePointsFlag) {
    if(removePromoFlag){
      setPoints('')
    }
    
    if(removePointsFlag){
      setPromoCode('')
    }
    
    BookingService.calculatePrice(
      setLoadingFlag,
      type,
      setTotalCalculation,
      removePromoFlag ? '' : promoCode,
      (!removePromoFlag && promoCode) || removePointsFlag ? '' :points
    );
  }

  function addAddress(item) {
    const arr = addresses;
    arr.unshift(item);
    setUserAddresses(arr);
  }

  function nextStep() {
    if (currentStep === 3) {
      BookingService.book(
        currentMethodType,
        promoCode,
        points,
        type,
        setPaymentUrl,
        setLoadingFlag,
        setSuccessOrder,
        selectedAddress
      );
    }

    if (currentStep !== 3) {
      setButtonTitle(
        currentMethodType === "pre-paid"
          ? t("proceedtopayment")
          : t("confirmbooking")
      );
      setCurrentStep(currentStep + 1);
    }
  }

  async function getCurrentUser() {
    setLoadingFlag(true);
    await AuthService.getUserData(
      null,
      null,
      setCurrentUser,
      setUserAddresses,
      setLoadingFlag
    );
    await setSelectedAddress(
      addresses?.find((item) => item.defaultAddress === true)?.id
    );
    setLoadingFlag(false);
  }

  const getRegions = async () => {
    await getData("/cities", {
      locale: i18n.language,
    })
      .then((response) => {
        setRegions(response.data);
      })
      .catch((error) => {
        setLoadingFlag(false);
      });
  };

  useEffect(() => {
    getCurrentUser();
    getTotalCalculation();
    const url = window.location.pathname.split("-");
    if (url[0] === "/clinic") {
      setType("clinic");
    } else if (url[0] === "/tele") {
      setType("tele-health");
      setCurrentMethodType("pre_paid")
    } else if (url[0] === "/home") {
      getRegions();
      setType("home-visit");
    }
  }, [addresses.length]);

  return successOrder ? (
    <SuccessMessage
      message={
        paymentUrl
          ? t("order.redirectsuccessmessage")
          : t("order.successmessage")
      }
      redirectURL={paymentUrl}
      setLoadingFlag={setLoadingFlag}
      cartFlag={true}
    />
  ) : (
    <div>
      <AddAddress
        modalOpen={modalAddress}
        setModalOpen={setModalAddress}
        cities={regions}
        setLoadingFlag={setLoadingFlag}
        AddAddressToArray={addAddress}
      />
      <div className="cart-view d-flex flex-column">
        <StepsBar
          currentStep={currentStep}
          stepOne={t("checkbookingdetails")}
          stepTwo={t("paymentdetails")}
          stepThree={t("checkout")}
        />
        <div className="d-flex gap1per flex-wrap">
          {bookingItems
            ?.filter((booking_item) => booking_item.type === type)
            .map((item, key) => (
              <div
                className="product-detailsplaceholder-parent d-flex mb-3 width62per flex-wrap"
                key={`${key}-booking-item`}
              >
                <img  loading="lazy"
                  className="medicine-1-icon"
                  alt="cart-img"
                  src={ item?.img
                    ? item?.img
                    : "./img/doctor-placeholder.jpg"}
                />
                <div className="flex-column d-flex doctor-appointment-div">
                  <b className="product-name-placeholder">{item?.name}</b>
                  <div className="d-flex gap1per flex-wrap">
                    <div className="select-date">
                      <div className="select-dateicon align-items-center d-flex gap1per">
                        <img  loading="lazy"
                          className="calendar-today-icon"
                          alt=""
                          src="./img/calendar_today.svg"
                        />
                        <b className="time-slot-p">{t("date")}</b>
                      </div>
                      <div className="date">
                        <div className="div">{item.date}</div>
                      </div>
                    </div>
                    <div className="select-time-slot-parent">
                      <div className="select-time-slot">
                        <img  loading="lazy"
                          className="calendar-today-icon"
                          alt=""
                          src="./img/access_time.svg"
                        />
                        <b className="time-slot-p">{t("timeslot")}</b>
                      </div>
                      <div className="time-slot1">
                        <div className="div">
                          {new Date(
                            item?.timeslot?.attributes?.start
                          )?.toLocaleString(`${i18n.language}-EG`, {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="price ms-auto">
                      <div className="cost">{t("cost")}</div>
                      <div className="egp">{`${formatter().format(item.selectedService?.attributes?.fee)}`}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <CheckoutCard
            totalCalculation={totalCalculation}
            setPromoCode={setPromoCode}
            promoCode={promoCode}
            getTotalCalculation={getTotalCalculation}
            currentMethodType={currentMethodType}
            setCurrentMethodType={setCurrentMethodType}
            regions={regions}
            currentStep={currentStep}
            addresses={addresses}
            setSelectedAddress={setSelectedAddress}
            selectedAddress={selectedAddress}
            setLoadingFlag={setLoadingFlag}
            setAddresses={setUserAddresses}
            type={type}
            points={points}
            setPoints={setPoints}
          />
        </div>
      </div>
      <CartFooter
        onClick={nextStep}
        btnName={buttonTitle}
        disabled={
          (currentStep === 2 && !currentMethodType) ||
          (currentStep === 3 && type === "home-visit" && !selectedAddress)
        }
      />
    </div>
  );
};

export default BookingPayment;
