import { FilterSearch } from "../../components/FilterSearch/FilterSearch";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GettingService from "../../services/getting.service";
import { LabCard } from "../../components/LabCard/LabCard";
import ReactPaginate from "react-paginate";
import { ConvertToArabicNumbers } from "../../helpers/convertToArabic";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import { FeatureBanner } from "../../components/FeatureBanner/FeatureBanner";

const NursesList = ({ setLoadingFlag }) => {
  const { state } = useLocation();
  const { t, i18n } = useTranslation();
  const [searchName, setSearchName] = useState(state?.searchWord);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(8);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [supplierId, setSupplierId] = useState(state?.supplierId);
  const [suppliers, setSuppliers] = useState([]);
  const [content, setContent] = useState();

  const search = useCallback(async () => {
    let params = {
      "populate[supplier]": "*",
      "filters[supplier][type]": "nurse",
      "pagination[page]": offset,
      "pagination[pageSize]": limit,
      "filters[supplier][name][$eq]": "Al Razy",
      "filters[$or][0][name][$contains]": searchName ? searchName : null,
      "filters[$or][1][name_ar][$contains]": searchName ? searchName : null,
    };

    await GettingService.getProductsBySupplier(
      setProducts,
      setTotal,
      params,
      setLoadingFlag
    );
  }, [setLoadingFlag, searchName, supplierId, offset]);

  useEffect(() => {
    search();
  }, [search, i18n.language]);

  useEffect(() => {
    GettingService.getSuppliers(setSuppliers, "nurse", setLoadingFlag);
  }, []);

  useEffect(() => {
    setOffset(1);
  }, [searchName, supplierId]);

  return (
    <>
      <MetaTags
        setContent={setContent}
        type="nursing page"
        title={`${t("doxx")} - ${t("header.nursing")}`}
        description={`${t("nursing.banner.title")} ${t(
          "nursing.banner.title2"
        )} ${t("nursing.banner.desc")}`}
      />
      <div className="home-visit-div d-flex">
      <FeatureBanner
          type="nurse"
          color="bg-scan"
          title1={t("nursing.banner.title")}
          title2={t("nursing.banner.title2")}
          desc={content}
          imgSrc="./img/cutout-1@2x.png"
        />
        <FilterSearch
          setLoadingFlag={setLoadingFlag}
          setSearchName={setSearchName}
          search={search}
          setSupplierId={setSupplierId}
          selectedSupplierId={supplierId}
          type="nurse"
          suppliers={suppliers}
          total={total ? total : 0}
        />
        <div className="d-flex flex-column cards-doctors-div">
          <div className="d-flex gap-3 m-auto"></div>
          <div className="d-flex flex-column ">
            <div className="d-flex flex-column mt-4">
              <div className="doctor-cards mb-4 pt-3">
                {products?.map((item, key) => (
                  <LabCard
                    key={`nurse-card-${key}`}
                    name={item?.attributes?.name}
                    price={item?.attributes?.price}
                    name_ar={item?.attributes?.name_ar}
                    id={item?.id}
                    type="nurse"
                  />
                ))}
              </div>
            </div>
            <div className="pagination-container">
              <ReactPaginate
                breakLabel="..."
                nextLabel={t("pagination.next")}
                onPageChange={(e) => {
                  setOffset(e.selected + 1);
                }}
                pageRangeDisplayed={limit}
                pageCount={Math.ceil(total / limit)}
                previousLabel={t("pagination.prev")}
                renderOnZeroPageCount={null}
                disabledClassName="greyed-out"
                pageLabelBuilder={(p) => ConvertToArabicNumbers(p)}
              />
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default NursesList;
