

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Visibility2 } from "../../icons/Visibility2";
import { Visibility3 } from "../../icons/Visibility3";
import "./style.css";

export const PasswordInput = ({ property1, className, placeholder, onChange, error, divClass }) => {
  const[passwordVisible, setPasswordVisible] = useState(false);
  return (
    <div className={`${divClass} position-relative`}>
      <input
        type={passwordVisible ? "text" :"password"}
        className={`password-input  property-1-0-${property1} ${className}`}
        placeholder={placeholder}
        onChange={(e) => onChange(e?.target?.value)}
      />
      {!passwordVisible && (
        <Visibility2 className="visibility pointer-hover " onClick={setPasswordVisible}/>
      )}
      {passwordVisible && (
        <Visibility3 className="visibility pointer-hover " onClick={setPasswordVisible}/>
      )}
      <p className="text-danger  h6 position-absolute  text-start">{error}</p>
    </div>
  );
};

PasswordInput.propTypes = {
  property1: PropTypes.oneOf(["password", "passwordvisible", "empty"]),
};
