import "./style.css";
import { DoctorCards } from "../../components/DoctorCards/DoctorCards";
import { FilterSearch } from "../../components/FilterSearch/FilterSearch";
import { FeatureBanner } from "../../components/FeatureBanner/FeatureBanner";
import { useCallback, useEffect, useState } from "react";
import SearchService from "../../services/search.service";
import { useLocation } from "react-router-dom";
import { BookingModal } from "../../components/BookingModal/BookingModal";
import { useTranslation } from "react-i18next";
import { MetaTags } from "../../components/MetaTags/MetaTags";

const HomeVisit = ({ setLoadingFlag }) => {
  const { state } = useLocation();
  const [result, setResult] = useState({});
  const [selectedRegion, setSelectedRegion] = useState(state?.city);
  const [selectedSpecialty, setSelectedSpecialty] = useState(state?.speciality);
  const [selectedGrade, setSelectedGrade] = useState();
  const [searchName, setSearchName] = useState(state?.searchWord);
  const [gender, setGender] = useState("");
  const [offset, setOffset] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [item, setItem] = useState({});
  const [content, setContent] = useState();
  const originalType = "home-visit";
  const { i18n, t } = useTranslation();
  const [doctorKey, setDoctorKey] = useState(0);
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search).get('search');

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  const search = useCallback(async () => {
    if(!searchName && searchParam){
      setSearchName(searchParam);
    }

    await SearchService.searchForHomevisit(
      {
        city: selectedRegion,
        speciality: selectedSpecialty,
        doctor_title: selectedGrade,
        searchWord: searchName || searchParam,
        gender,
        limit: 8,
        offset,
        locale: i18n.language,
        "coordinates[lat]": position?.latitude,
        "coordinates[long]": position?.longitude
      },
      (response) => {
        setResult(response);
      },
      setLoadingFlag
    );
  }, [
    setLoadingFlag,
    gender,
    offset,
    selectedRegion,
    selectedSpecialty,
    selectedGrade,
    searchName,
    offset,
    setResult,
  ]);

  useEffect(() => {
    search();
    setDoctorKey(doctorKey + 1);
  }, [search, i18n.language, position?.latitude, position?.longitude]);

  useEffect(() => {
    setOffset(1);
  }, [gender, selectedRegion, selectedSpecialty, searchName]);

  return (
    <>
      {
        searchName && result?.result?.length ?
        <MetaTags setContent={setContent} type={"home visit page"} title={ `${t('doxx')} - ${searchName}`} description={`${t("homevisit.banner.title2")} ${t("homevisit.banner.desc")} - ${searchName}`} />
        : <MetaTags setContent={setContent} type={"home visit page"} title={`${t("doxx")} - ${t("header.homevisit")}`} description={`${t("homevisit.banner.title2")}  ${"homevisit.banner.desc"}`}/>
      }
      <div className="home-visit-div d-flex">
        <FilterSearch
          setLoadingFlag={setLoadingFlag}
          setSearchName={setSearchName}
          setSelectedSpecialty={setSelectedSpecialty}
          setSelectedRegion={setSelectedRegion}
          setGender={setGender}
          searchName={searchName}
          selectedSpecialty={selectedSpecialty}
          selectedRegion={selectedRegion}
          selectedGrade={selectedGrade}
          setSelectedGrade={setSelectedGrade}
          gender={gender}
          search={search}
          total={result?.orginalTotal ? result?.orginalTotal : 0}
        />
        <div className="d-flex flex-column cards-doctors-div">
          <FeatureBanner
            type="home-visit"
            title1={t("homevisit.banner.title")}
            title2={t("homevisit.banner.title2")}
            desc={t("homevisit.banner.desc")}
            desc2={content}
            imgSrc={"./img/patient-with-doctor-at-home-cutout 1.png"}
          />
          <DoctorCards
            result={result}
            limit={8}
            setOffset={setOffset}
            offset={offset}
            setModalOpen={setModalOpen}
            setItem={setItem}
            type="home-visit"
            doctorKey={doctorKey}
          />
          <BookingModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            item={item}
            originalType={originalType}
            setLoadingFlag={setLoadingFlag}
          />
        </div>
      </div>
    </>
  );
};

export default HomeVisit;
