import { useTranslation } from "react-i18next";
import "../BookingModal/style.css";
import "react-calendar/dist/Calendar.css";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export const AskDoctor = ({}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [minimized, setMinimized] = useState(true);
  useEffect(()=>{
    setTimeout(() => {
      if(window.location.pathname === '/'){
        setMinimized(false);
      }
    }, 5000);
  },[])
  return (
    <div className={`ask-doc-div-${i18n.language} ${minimized ? 'ask-doc-div-minimize' : ''}`}>
      {minimized ? (
        <img  loading="lazy"
          src="/img/fullscreen.png"
          className={`${i18n.language === 'ar' ? 'minimize-icon-ask-ar' : 'minimize-icon-ask' }`}
          onClick={() => setMinimized(false)}
        />
      ) : (
        <img  loading="lazy"
          src="/img/minus.png"
          className={`${i18n.language === 'ar' ? 'minimize-icon-ask-ar' : 'minimize-icon-ask' }`}
          onClick={() => setMinimized(true)}
        />
      )}

      <div className="">
        <div className={`ask-doc-title ${i18n.language === 'ar' ? 'ltr' : ''}`}>{t("askdoctor.title")}</div>
        <div className={`ask-doc-content ${minimized? 'd-none':''} ${i18n.language === 'ar' ? 'ltr' : ''}`}>
          {t("askdoctor.content")}
        </div>
        <div
          className={`submit-question-button pointer my-3 ${minimized? 'd-none':''}`}
          onClick={() =>
            navigate("/dashboard", {
              state: {
                selected: "medical",
              },
            })
          }
        >
          <b className="submit-question">{t("landing.submitquestion")}</b>
        </div>
      </div>
      <div>
        <img  loading="lazy"
          src="./img/Doctor Image.png"
          alt="doctor-img-ask"
          className={`${i18n.language === 'ar' ? 'doctor-img-ask-ar ' :'doctor-img-ask '} ${minimized? 'd-none':''}`}
        />
      </div>
    </div>
  );
};
