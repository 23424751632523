const SeoScreens = [
  {
    name: "home page",
  },
  {
    name: "clinics page",
  },
  {
    name: "labs and scans page",
  },
  {
    name: "labs page",
  },
  {
    name: "scans page",
  },
  {
    name: "telehealth page",
  },
  {
    name: "home visit page",
  },
  {
    name: "nursing page",
  },
  {
    name: "pharma page",
  },
  {
    name: "pharma products page",
  },
  {
    name: "family plans page",
  },
  {
    name: "personal plans page",
  },
];
export default SeoScreens;
