 import React from "react";

export const NumberInput = ({
  className,
  placeholder,
  onChange,
  error,
  divClass,
  value,
}) => {
  return (
    <div className={`${divClass}`}>
      <input
        type="text"
        className={`email-input ${className}`}
        placeholder={placeholder}
        onChange={(e) => {
          const inputValue = e.target.value;
          const numericValue = inputValue.replace(/[^0-9]/g, ''); // Removes non-numeric characters

          if (numericValue !== value) {
            onChange(numericValue);
          }
        }}
        value={value !== undefined ? value : ''}
        maxLength={6}
      />
      <p className="text-danger  h6 position-absolute  text-start">{error}</p>
    </div>
  );
};
