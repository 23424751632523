import { useTranslation } from "react-i18next";
import "./style.css";
import { useState } from "react";

export const PlanCard = ({ plan, subscribe, type }) => {
  const { t } = useTranslation();
  const [showAllFeatures, setShowAllFeatures] = useState(false);
  return (
    <div className="plan-card m-auto">
      <div className="titlepicture">
        <img  loading="lazy"
          className="image-placeholder-icon"
          alt=""
          src={
              // type === "family"
              // ? plan?.attributes?.type === "basic"
              //   ? "./img/family-basic.png"
              //   : "./img/family-plus.png"
              plan?.attributes?.type === "basic"
              ? "./img/personal-basic.png"
              : "./img/personal-plus.png"
          }
        />
        {/* <div className="family">{plan?.attributes?.type}</div> */}
        <b className="standard">{plan?.attributes?.name}</b>
      </div>
      <div className="price position-relative">
        <b className="pricing-plans">
          {plan?.attributes?.subscription_plan_option?.data?.attributes?.fee}
        </b>
        <i className="renewed-yearly">
          {plan?.attributes?.subscription_plan_option?.data?.attributes
            ?.duration === "yearly"
            ? t("renewedyearly")
            : t("renewedmonthly")}
        </i>
        <div className="egp">{t("egp")}</div>
      </div>
      <div className="featurescta">
        <div
          className={`features d-flex flex-column ${
            showAllFeatures ? "height-fit" : ""
          }`}
        >
          <div>
            <i
              className={`features1 pointer ${
                showAllFeatures ? "light-blue-text" : ""
              }`}
              onClick={() => setShowAllFeatures(showAllFeatures ? false : true)}
            >
              {t("subscription.features")}
            </i>
            <img  loading="lazy"
              className="arrow-drop-down-icon pointer"
              alt="drop-arrow"
              src="./img/arrow-drop-down@2x.png"
              onClick={() => setShowAllFeatures(showAllFeatures ? false : true)}
            />
          </div>

          <div className={showAllFeatures ? "" : "d-none"}>
            <ul className="plan-features">
              <li className="m-0 p-0">
                <div>
                  {
                    plan?.attributes?.subscription_plan_option?.data?.attributes
                      ?.chronic_pharmacy_percentage_discount
                  }
                  {`% ${t("plan.offon")} ${t("plan.feature.chronic")}`}
                </div>
              </li>
              <li className="m-0 p-0">
                <div>
                  {
                    plan?.attributes?.subscription_plan_option?.data?.attributes
                      ?.imported_pharmacy_percentage_discount
                  }
                  {`% ${t("plan.offon")} ${t("plan.feature.imported")}`}
                </div>
              </li>
              <li className="m-0 p-0 ">
                <div>
                  {
                    plan?.attributes?.subscription_plan_option?.data?.attributes
                      ?.pharmacy_percentage_discount
                  }
                  {`% ${t("plan.offon")} ${t("plan.feature.other")}`}
                </div>
              </li>
              <li className="m-0 p-0 ">
                <div>
                  {
                    plan?.attributes?.subscription_plan_option?.data?.attributes
                      ?.lab_percentage_discount
                  }
                  {`% ${t("plan.offon")} ${t("plan.feature.lab")}`}
                </div>
              </li>
              <li className="m-0 p-0">
                <div>
                  {
                    plan?.attributes?.subscription_plan_option?.data?.attributes
                      ?.scan_percentage_discount
                  }
                  {`% ${t("plan.offon")} ${t("plan.feature.scan")}`}
                </div>
              </li>
              <li className="m-0 p-0 ">
                <div>
                  {
                    plan?.attributes?.subscription_plan_option?.data?.attributes
                      ?.doctor_percentage_discount
                  }
                  {`% ${t("plan.offon")} ${t("plan.feature.doctor")}`}
                </div>
              </li>
              <li className="m-0 p-0">
                <div>
                  {
                    plan?.attributes?.subscription_plan_option?.data?.attributes
                      ?.free_teleconsultation
                  }
                  {`% ${t("plan.offon")} ${t("plan.feature.tele")}`}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="subscribe-now-button-standard pointer"
          onClick={() => subscribe()}
        >
          <b className="pricing-plans">{t("subscription.subscribenow")}</b>
        </div>
      </div>
    </div>
  );
};
