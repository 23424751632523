export const Tab = ({ className, imgSrc, text1, text2 , active, onclick }) => {
  return (
    <div onClick={()=>onclick()} className={`${className} ${!active ? ' non-active-tab' : 'active-tab'}`}>
      <img  loading="lazy" className='book-online-icon' alt="" src={imgSrc} />
      <div className="quick-action-bar-text">
        <div className={`lorem-ipsum ${active ? 'teritiarymain' :''}`}>{text1}</div>
        <div className={`examination-or-proceedure ${active ? 'teritiarymain' :''}`}>{text2}</div>
      </div>
      {active ?  <img  loading="lazy"
        alt="active-indicator"
        className="active-indicator"
        src="./img/Indicator.svg"
      /> : ''}
    </div>
  );
};
