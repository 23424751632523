import { useTranslation } from "react-i18next";
import { getPharmaImage } from "../../helpers/getPharmaImage";
import { checkObjExist } from "../../helpers/checkObjExist";
import { AddOrMinusCart } from "../AddOrMinusCart/AddOrMinusCart";
import { addToCart } from "../../helpers/addToCart";
import { useEffect, useState } from "react";
import { formatter } from "../../helpers/currencyFormatter";

export const PharmaCard = ({ name, price, name_ar, id }) => {
  const { t, i18n } = useTranslation();
  const [item, setItem] = useState();

  useEffect(() => {
    const listenStorageChange = () => {
      setItem(
        checkObjExist(
          id,
          localStorage.getItem("pharmacy-cart") ? JSON.parse(localStorage.getItem("pharmacy-cart")) : [], "pharmacy"
        )
      );
    };
    listenStorageChange();

    window.addEventListener("storage", listenStorageChange);
    return () => window.removeEventListener("storage", listenStorageChange);
  }, []);

  return (
    <div className="doctor-card height-auto">
      <div className="image-container-card">
        <img  loading="lazy"
          className="image-placeholder-icon"
          alt={i18n.language === "ar" && name_ar ? name_ar : name}
          src={getPharmaImage(name)}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "./img/image-placeholder.png";
          }}
        />
      </div>
      <div className="doctor-name-placeholder">
        <div className="doctor-name-placeholder1">
          {i18n.language === "ar" && name_ar ? name_ar : name}
        </div>
      </div>

      <div className="d-flex justify-content-center width100per">
        <div className="costlocation">
          <div className="price-placeholder">
            <div className="cost">{t("doctor.cost")}</div>
            <div className="egp">{`${formatter(i18n?.language).format(price)}`}</div>
          </div>
        </div>
        {checkObjExist(
          id,
          localStorage.getItem("pharmacy-cart") ? JSON.parse(localStorage.getItem("pharmacy-cart")) : [], "pharmacy"
        ) ? (
          <AddOrMinusCart item={item} type="pharmacy"/>
        ) : (
          <img  loading="lazy"
            src="./img/Add to Cart.png"
            onClick={() => {
              addToCart(name, price, name_ar, id, "pharmacy");
            }}
            alt="add-to-cart"
            className="add-to-cart-btn pointer"
          />
        )}
      </div>
    </div>
  );
};
