import { useEffect, useState } from "react";
import "./style.css";
import { CartFooter } from "../../components/CartFooter/CartFooter";
import { StepsBar } from "../../components/StepsBar/StepsBar";
import { CheckoutCard } from "../../components/CheckoutCard/CheckoutCard";
import BookingService from "../../services/booking.service";
import { RadioButton } from "../../components/RadioButton/RadioButton";
import { useTranslation } from "react-i18next";
import { CartItems } from "../../components/CartItems/CartItems";
import AuthService from "../../services/auth.service";
import { getData } from "../../services/apiService";
import { SuccessMessage } from "../../components/SuccessMessage/SuccessMessage";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { MetaTags } from "../../components/MetaTags/MetaTags";

const Cart = ({ setLoadingFlag }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [buttonTitle, setButtonTitle] = useState(t("checkout"));
  const [currentStep, setCurrentStep] = useState(2);
  const [promoCode, setPromoCode] = useState("");
  const [selectedAddress, setSelectedAddress] = useState();
  const [totalCalculation, setTotalCalculation] = useState({});
  const [type, setType] = useState("");
  const [serviceType, setServiceType] = useState("lab");
  const [currentMethodType, setCurrentMethodType] = useState("post_paid");
  const [pharmaCart, setPharmaCart] = useState();
  const [labCart, setLabCart] = useState();
  const [scanCart, setScanCart] = useState();
  const [nursingCart, setNursingCart] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [successOrder, setSuccessOrder] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [regions, setRegions] = useState();
  const [points, setPoints] = useState();
  const [paymentUrl, setPaymentUrl] = useState("");

  function getTotalCalculation(selectedType, removePromoFlag, removePointsFlag) {
    if(removePointsFlag){
      setPoints('');
    }

    if (removePromoFlag) {
      setPromoCode("");
    }

    if(!removePromoFlag && promoCode){
      setPoints('');
    }

    BookingService.calculateCartPrice(
      setLoadingFlag,
      selectedType,
      setTotalCalculation,
      removePromoFlag ? "" : promoCode,
      (!removePromoFlag && promoCode) || removePointsFlag ? '' :points
    );
  }

  function nextStep() {
    if (currentStep === 3) {
      BookingService.checkout(
        currentMethodType,
        promoCode,
        points,
        type,
        setPaymentUrl,
        setLoadingFlag,
        setSuccessOrder,
        selectedAddress,
        setLabCart,
        setNursingCart,
        setScanCart,
        setPharmaCart,
        serviceType
      );
    }

    if (currentStep !== 3) {
      setCurrentStep(currentStep + 1);
    }
  }

  async function getCurrentUser() {
    setLoadingFlag(true);
    await AuthService.getUserData(
      null,
      null,
      setCurrentUser,
      setAddresses,
      setLoadingFlag
    );
    await setSelectedAddress(
      addresses?.find((item) => item.defaultAddress === true)?.id
    );
    setLoadingFlag(false);
  }

  const getRegions = async () => {
    await getData("/cities", {
      locale: i18n.language,
    })
      .then((response) => {
        setRegions(response.data);
      })
      .catch((error) => {
        setLoadingFlag(false);
      });
  };

  useEffect(() => {
    const listenStorageChange = () => {
      let pharmaCart = localStorage.getItem("pharmacy-cart")
        ? JSON.parse(localStorage.getItem("pharmacy-cart"))
        : [];
      let nursingCart = localStorage.getItem("nurse-cart")
        ? JSON.parse(localStorage.getItem("nurse-cart"))
        : [];
      let labCart = localStorage.getItem("lab-cart")
        ? JSON.parse(localStorage.getItem("lab-cart"))
        : [];
      let scanCart = localStorage.getItem("scan-cart")
        ? JSON.parse(localStorage.getItem("scan-cart"))
        : [];
      setType(
        type ||
          (pharmaCart?.length
            ? "pharmacy"
            : labCart?.length
            ? "lab"
            : scanCart?.length
            ? "scan"
            : nursingCart?.length
            ? "nurse"
            : "")
      );
      setPharmaCart(pharmaCart);
      setNursingCart(nursingCart);
      setLabCart(labCart);
      setScanCart(scanCart);
      getTotalCalculation(
        type ||
          (pharmaCart?.length
            ? "pharmacy"
            : labCart?.length
            ? "lab"
            : scanCart?.length
            ? "scan"
            : nursingCart?.length
            ? "nurse"
            : "")
      );
    };
    listenStorageChange();

    window.addEventListener("storage", listenStorageChange);
    return () => window.removeEventListener("storage", listenStorageChange);
  }, []);

  useEffect(() => {
    getTotalCalculation(type);
    setCurrentStep(2);
  }, [type]);

  useEffect(() => {
    getCurrentUser();
    getRegions();
  }, [addresses?.length]);

  return (
    <>
    <MetaTags title={`${t('doxx')} - ${t('cart')}`} description={`${t('submit.your.order')} ${t('hassle.of.phone')}`} />
      {successOrder ? (
        <SuccessMessage
          message={
            paymentUrl
              ? t("order.redirectsuccessmessage")
              : t("order.successmessage")
          }
          redirectURL={paymentUrl}
          setLoadingFlag={setLoadingFlag}
          cartFlag={true}
          setSuccess={setSuccessOrder}
        />
      ) : !pharmaCart?.length &&
        !labCart?.length &&
        !scanCart?.length &&
        !nursingCart?.length ? (
        <div>
          <Header setLoadingFlag={setLoadingFlag} />
          <div className="cart-empty-div">
            <div className="cart-empty-img">
              <img  loading="lazy" alt="cart-img" src="./img/shopping-cart@2x.png" />
              <p className="clinic">Your cart is empty</p>
            </div>
            <div
              className="cart-return-button pointer empty-cart-return"
              onClick={() => navigate("/")}
            >
              <img  loading="lazy"
                className="arrow-back-icon"
                alt=""
                src="/img/arrow-back@2x.png"
              />
              <div className="total">{t("backtohome")}</div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div className="cart-view d-flex flex-column">
          <StepsBar
            currentStep={currentStep}
            stepOne={t("checkcartdetails")}
            stepTwo={t("paymentdetails")}
            stepThree={t("checkout")}
          />
          <div className="d-flex gap-4 my-3 flex-wrap">
            {pharmaCart?.length &&
            (labCart?.length || scanCart?.length || nursingCart?.length) ? (
              <RadioButton
                name="type"
                id="pharmacy-radio"
                type="pharmacy"
                label={t("header.pharmacy")}
                currentType={type}
                onChange={() => setType("pharmacy")}
                labelClassName="modal-checkbox-label"
              />
            ) : (
              ""
            )}
            {labCart?.length &&
            (pharmaCart?.length || scanCart?.length || nursingCart?.length) ? (
              <RadioButton
                name="type"
                id="lab-radio"
                type="lab"
                label={t("header.lab")}
                currentType={type}
                onChange={() => setType("lab")}
                labelClassName="modal-checkbox-label"
              />
            ) : (
              ""
            )}
            {scanCart?.length &&
            (pharmaCart?.length || labCart?.length || nursingCart?.length) ? (
              <RadioButton
                name="type"
                id="scan-radio"
                type="scan"
                label={t("header.lab")}
                currentType={type}
                onChange={() => setType("scan")}
                labelClassName="modal-checkbox-label"
              />
            ) : (
              ""
            )}
            {nursingCart?.length &&
            (pharmaCart?.length || scanCart?.length || labCart?.length) ? (
              <RadioButton
                name="type"
                id="nursing-radio"
                type="nurse"
                label={t("header.nursing")}
                currentType={type}
                onChange={() => setType("nurse")}
                labelClassName="modal-checkbox-label"
              />
            ) : (
              ""
            )}
          </div>
          <div className="d-flex gap-4 flex-wrap">
            <CartItems
              items={
                type === "pharmacy"
                  ? pharmaCart
                  : type === "lab"
                  ? labCart
                  : type === "scan"
                  ? scanCart
                  : nursingCart
              }
              type={type}
            />
            <CheckoutCard
              totalCalculation={totalCalculation}
              setPromoCode={setPromoCode}
              promoCode={promoCode}
              getTotalCalculation={getTotalCalculation}
              currentMethodType={currentMethodType}
              setCurrentMethodType={setCurrentMethodType}
              regions={regions}
              currentStep={currentStep}
              addresses={addresses}
              setSelectedAddress={setSelectedAddress}
              selectedAddress={selectedAddress}
              setLoadingFlag={setLoadingFlag}
              setAddresses={setAddresses}
              type={type}
              serviceType={serviceType}
              setServiceType={setServiceType}
              points={points}
              setPoints={setPoints}
            />
          </div>
          <CartFooter
            onClick={nextStep}
            btnName={buttonTitle}
            disabled={
              (currentStep === 2 && !currentMethodType) ||
              ((type === "pharmacy" ||
                serviceType === "home" ||
                type === "nurse") &&
                !selectedAddress)
            }
          />
        </div>
      )}
    </>
  );
};

export default Cart;
