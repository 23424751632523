import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import GettingService from "../../services/getting.service";
import { AddMemberModal } from "./AddMemberModal";
import { SignInUpButton } from "../SignInUpButton";
import { deleteData, getData } from "../../services/apiService";
import TokenService from "../../services/token.service";
import { toast } from "react-toastify";

export const FamilyHistory = ({ setLoadingFlag }) => {
  const [chronicDiseases, setChronicDiseases] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const [familyHistory, setFamilyHistory] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const user = TokenService.getUser();

  async function getFamilyHistory() {
    setLoadingFlag(true);
    let params = {
      "populate[family_histories][populate]": "*",
    };
    await getData(`/patients/${user?.patient_id}`, params, true).then(
      (response) => {
        const familyHistoryArr = [];
        response?.data?.attributes?.family_histories?.data.forEach(
          (element) => {
            familyHistoryArr.push({
              label: element?.attributes?.name,
              value: element?.id,
              relationship: element?.attributes?.relationship,
              gender: element?.attributes?.gender,
              diseases: element?.attributes?.diseases?.data,
              phone: element?.attributes?.phone,
              birthdate: element?.attributes?.birthdate
            });
          }
        );
        setFamilyHistory(familyHistoryArr);
        setLoadingFlag(false);
      }
    );
  }

  function addNewMember(item) {
    getFamilyHistory();
  }

  function removeMember(id){
    let familyHistoryArr = familyHistory;
    deleteData('/family-histories',id).then((res)=>{
      familyHistoryArr = familyHistoryArr.filter((item)=>item?.value !== id);
        setFamilyHistory(familyHistoryArr);
        toast.success(t("delete.success"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      
    }).catch(()=>{
      toast.error(t("delete.failed"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
  }

  function editMember(item){
   getFamilyHistory()
  }

  useEffect(() => {
    GettingService.getChronicDiseases(setChronicDiseases, setLoadingFlag);
    getFamilyHistory();
  }, []);

  return (
    <div className="mt-4 d-flex flex-wrap gap-4 settings-inputs-container m-auto  " >
      <table
        className="mt-3 width100per "
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
      >
        <thead className="">
          <tr className="head-family-table">
            <th className="p-2">{t("name")}</th>
            <th className="px-4">{t("gender")}</th>
            <th className="p-2">{t("relationship")}</th>
            <th className="p-2">{t("phonenumber")}</th>
            <th className="p-2">{t("birthdate")}</th>
            <th className="p-2">{t("dashboard.chronicdiseases")}</th> 
            <th className="p-2"></th>
          </tr>
        </thead>
        <tbody>
          {familyHistory?.map((item, key) => (
            <tr key={`${key}-item`}>
              <td className="item-width clinic p-2">{item?.label}</td>
              <td className="clinic p-2 px-4">{item?.gender}</td>
              <td className="clinic p-2">{item?.relationship}</td>
              <td className="clinic p-2">
                {/* {item?.diseases?.map((dis, key)=>(
                        
                            <span>{dis?.attributes?.name  + (item?.diseases?.length !== (key + 1) ? ' - ' : '')}</span>
                            
                        ))} */}
                {item?.phone}
              </td>
              <td className="clinic p-2">{item?.birthdate}</td>
              <td className="clinic p-2">{item?.diseases?.map((dis)=>(
                <p className="m-0">{dis?.attributes?.name}</p>  
              ))}</td>
              <td className="clinic p-2">
                <img  loading="lazy" onClick={()=>{setCurrentItem(item); setModalOpen(true)}}  src="./img/edit.png" className="width25px mx-2 pointer"/>
                <img  loading="lazy" onClick={()=>{removeMember(item?.value)}} src="./img/delete.png" className="width25px mx-2 pointer"/>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <SignInUpButton
        name={t("addfamilymember")}
        className="btn starts-in-5h mt-3 btn-primary"
        onClick={() => setModalOpen(true)}
      />
      <AddMemberModal
        addNewMember={addNewMember}
        modalOpen={modalOpen}
        chronicDiseases={chronicDiseases}
        setModalOpen={setModalOpen}
        currentItem={currentItem}
        editMember={editMember}
      />
    </div>
  );
};
