import { useTranslation } from "react-i18next";
import './style.css';
import { Header } from "../Header";
import { Footer } from "../Footer";

export const ErrorPage = ({}) => {
  const { t, i18n } = useTranslation();

  return (<div className="container my-3 error-page">
        <div className="error-box text-lg-center">
          <img  loading="lazy" src="./img/20944106.jpg" className="img-fluid width45per" alt="warning-error" />
          <h2 className="lorem-ipsum my-3">{t("somethingwentwrong")}</h2>
          <a href="/" className="btn btn-primary">
            <i className="feather-home me-2"></i>
            {t("backtohome")}
          </a>
        </div>
      </div>
  );
};
