import React, { useState } from "react";
import { EmailInput } from "../../components/EmailInput";
import { PasswordInput } from "../../components/PasswordInput";
import { SignInUpButton } from "../../components/SignInUpButton";
import validator from "validator";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


export const Login = ({ setLoadingFlag, type, regFlag }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  function onSubmit() {
    let errorsObj = {};
    setLoadingFlag(true);
    if (email && !validator.isEmail(email)) {
      errorsObj.email = t("email.error");
    }


    let data = {
        identifier: email,
        password,
        type: "support"
    };

    if (!Object.keys(errorsObj).length) {
      const result = AuthService.login(data, navigate, setLoadingFlag, true);

      result.catch((error) => {
        setLoadingFlag(false);
        if (error?.message === "Network Error") {
          errorsObj.auth = "Network Error";
        } else {
          errorsObj.auth = t(error?.response?.data?.error?.message);
        }
        setErrors(errorsObj);
      });
    } else {
      setLoadingFlag(false);
    }

    setErrors(errorsObj);
  }

  return (
    <div className="sign-in-flow d-flex">
        <div className="overlap-group-wrapper d-flex">
        <div className="overlap-group d-flex flex-wrap">
            <div className="rectangle d-flex flex-column">
            <a href="/">
                <img  loading="lazy" className="logo" alt="Logo" src="/img/logo.svg" />
            </a>
            <div className="my-auto img-container">
                <img  loading="lazy"
                className="illustration d-flex"
                alt="Illustration"
                src="/img/illustration.svg"
                />
            </div>
            <div className="mt-auto">
                <div className="text-wrapper-2 mb-3">
                {t("auth.welcometitle")}
                </div>
            </div>
            </div>
            <div className="group">
            <p className="p mb-3">
                Login to your SEO account
            </p>
            <EmailInput
                className="email-input-instance"
                property1="empty"
                placeholder={t("auth.email")}
                onChange={setEmail}
                error={errors?.email}
            />
            <PasswordInput
                className="password-input-instance"
                property1="empty"
                placeholder={t("auth.password")}
                onChange={setPassword}
                error={errors?.password}
                divClass={regFlag ? 'mb-3' : ''}
            />
            <div className="position-relative">
                <p className="text-danger h6 position-absolute text-start">
                {errors?.auth}
                </p>
            </div>
            <SignInUpButton
                className="sign-in-up-button-instance my-4 sign-in-up-button "
                onClick={onSubmit}
                state={
                (email && password)
                    ? "default"
                    : "greyed-out"
                }
                name={t("auth.signin")}
            />
            </div>
        </div>
        </div>
    </div>
  );
};
