import encryptionService from "./encryption.service";

const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  };
  
  const getLocalAccessToken = (seoFlag) => {
    const user = encryptionService.decrypt(localStorage.getItem(seoFlag ? "user_seo" : "user"));
    return user?.JWTtoken;
  };
  
  const updateLocalAccessToken = (token) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  };
  
  const getUser = (seoFlag) => {
    return (seoFlag && localStorage.getItem("user_seo")) ? encryptionService.decrypt(localStorage.getItem("user_seo")) :
    (!seoFlag && localStorage.getItem("user")) ? encryptionService.decrypt(localStorage.getItem("user")) : null;
  };
  
  const setUser = async (user, seoFlag) => {
    const encryptedData = await encryptionService.encrypt(user)
    localStorage.setItem(seoFlag ? "user_seo" :"user", encryptedData);
  };

  const removeUser = (seoFlag) => {
    if(seoFlag){
      localStorage.removeItem("user_seo");
    }else{
      localStorage.removeItem("user");
    }
  };
  
  const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
  };
  
  export default TokenService;