import i18n from "../i18n";
import { getData } from "./apiService";
import TokenService from "./token.service";

const getAllergies = async (setAllergies, setLoadingFlag) => {
  setLoadingFlag(true);
  await getData(
    "/allergies",
    {
      fields: "name",
    },
    true
  )
    .then((response) => {
      setLoadingFlag(false);
      setAllergies(response.data);
    })
    .catch(() => setLoadingFlag(false));
};

const getVaccines = async (setVaccines, setLoadingFlag) => {
  setLoadingFlag(true);
  getData(
    "/vaccines",
    {
      fields: "name",
    },
    true
  )
    .then((response) => {
      setLoadingFlag(false);
      setVaccines(response.data);
    })
    .catch(() => setLoadingFlag(false));
};

const getLifeStyles = async (setLifeStyles, setLoadingFlag) => {
  setLoadingFlag(true);
  getData(
    "/lifestyles",
    {
      "populate[lifestyle_options][fields][0]": "name",
    },
    true
  )
    .then((response) => {
      setLoadingFlag(false);
      setLifeStyles(response.data);
    })
    .catch(() => setLoadingFlag(false));
};

const getInsurances = async (setInsurances, setLoadingFlag) => {
  setLoadingFlag(true);
  getData(
    "/insurances",
    {
      fields: "name",
    },
    true
  )
    .then((response) => {
      setLoadingFlag(false);
      setInsurances(response.data);
    })
    .catch(() => setLoadingFlag(false));
};

const getFamilyHistory = async (setFamilyHistory, setLoadingFlag) => {
  setLoadingFlag(true);
  getData(
    "/family-histories",
    {
      fields: "name",
    },
    true
  )
    .then((response) => {
      setLoadingFlag(false);
      setFamilyHistory(response.data);
    })
    .catch(() => setLoadingFlag(false));
};

const getSurgeries = async (setSurgeries, setLoadingFlag) => {
  setLoadingFlag(true);
  getData(
    "/surgeries",
    {
      fields: "name",
    },
    true
  )
    .then((response) => {
      setLoadingFlag(false);
      setSurgeries(response.data);
    })
    .catch(() => setLoadingFlag(false));
};

const getChronicDiseases = async (setChronicDiseases, setLoadingFlag) => {
  setLoadingFlag(true);
  await getData(
    "/chronic-diseases",
    {
      fields: "name",
    },
    true
  )
    .then((response) => {
      setLoadingFlag(false);
      setChronicDiseases(response.data);
    })
    .catch(() => setLoadingFlag(false));
};

const getOrders = async (setOrders, offset, selectedType, setLoadingFlag) => {
  setLoadingFlag(true);
  let userData = TokenService.getUser();
  
  let params = {
    locale: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
    "populate[doctor][populate]":
    "profile,specialities,profile.profilePicture,profile.user",
    "populate[appointment][populate][timeslot]": "*",
    "populate":"line_items",
    "sort": "id:DESC",
    "populate[lab_order]":"*",
    "populate[scan_order]":"*",
    "populate[nurse_order]":"*",
    "populate[pharmacy_order]":"*",
    "filters[patient][id]": userData?.profile?.patient?.id
  };

  params = Object.assign(
    params,
    selectedType === "appointment"
      ? {
          "filters[type]": "appointment",
          "populate[appointment][populate][consultation][populate]":"*",
          "populate[appointment][populate]":"doctor_service"
        }
      : selectedType
      ? {
          "filters[type][$notContains]": "appointment",
        }
      : {}
  );

  await getData("/orders", params, true)
    .then((response) => {
      setLoadingFlag(false);
      setOrders(response.data);
    })
    .catch(() => setLoadingFlag(false));
};

const getAddresses = async (setAddresses, setLoadingFlag) => {
  setLoadingFlag(true);
  await getData(
    "/addresses",
    {
      locale: localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "en",
    },
    true
  )
    .then((response) => {
      setLoadingFlag(false);
      setAddresses(response.data);
    })
    .catch(() => setLoadingFlag(false));
};

const getNotifications = async (setNotifications, language) => {
  await getData(
    "/notifications",
    {
      locale: language,
    },
    true
  )
    .then((response) => {
      setNotifications(response.data);
    })
    .catch(() => {});
};

const getSuppliers = async (setSuppliers, type, setLoadingFlag) => {
  setLoadingFlag(true);
  await getData(
    "/suppliers",
    {
      locale: localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "en",
      "filters[$and][0][type][$eq]": type,
      "populate[profile][fields]": "imageUrl",
    }
  )
    .then((response) => {
      setLoadingFlag(false);
      setSuppliers(response.data);
    })
    .catch(() => setLoadingFlag(false));
};

const getCategories = async (
  setCategories,
  type,
  supplierId,
  setLoadingFlag
) => {
  setLoadingFlag(true);
  await getData("/product-categories", {
    locale: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
    "filters[$and][0][supplier][type]": type,
    "filters[supplier][id]": supplierId,
    populate: "image",
  })
    .then((response) => {
      setLoadingFlag(false);
      setCategories(response.data);
    })
    .catch(() => setLoadingFlag(false));
};

const getProductsByCategory = async (
  categoryId,
  setProducts,
  type,
  setLoadingFlag,
  offset,
  searchName,
  setTotal
) => {
  setLoadingFlag(true);
  let params = {
    locale: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
    "pagination[page]": offset,
    "pagination[pageSize]": 8,
    "filters[supplier][type]":"pharmacy",
    "sort[numberOfPurchases]":"Desc"
  };
  if (categoryId) {
    params = Object.assign(params, {
      "filters[$and][0][product_category][id][$eq]": categoryId,
    });
  }

  params = Object.assign(params, {
    "filters[$or][0][name][$contains]": searchName ? searchName : null,
    "filters[$or][1][name_ar][$contains]": searchName ? searchName : null,
  });

  await getData("/supplier-products", params)
    .then((response) => {
      setLoadingFlag(false);
      setProducts(response.data);
      setTotal(response?.meta?.pagination?.total);
    })
    .catch(() => setLoadingFlag(false));
};

const getProductsBySupplier = async (
  setProducts,
  setTotal,
  params,
  setLoadingFlag
) => {
  setLoadingFlag(true);
  await getData("/supplier-products", params)
    .then((response) => {
      setLoadingFlag(false);
      setProducts(response.data);
      setTotal(response?.meta?.pagination?.total);
    })
    .catch(() => setLoadingFlag(false));
};

const getPopularProducts = async (setProducts, setLoadingFlag) => {
  setLoadingFlag(true);
  await getData("/line-items/popular", {
    locale: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
    "populate[image]": "*",
  })
    .then((response) => {
      setLoadingFlag(false);
      setProducts(response?.result);
    })
    .catch(() => setLoadingFlag(false));
};

const getProfile = async (id, setAllergies, setSurgeries, setVaccines, setChronicDiseases, setPatientLifeStyle , setInsuranceId, setLoadingFlag)=>{
  setLoadingFlag(true);
  let params = {
    "populate[allergies][fields][0]": "name",
    "populate[allergies][fields][1]": "allergyType",
    "populate[vaccines][fields][0]": "name",
    "populate[chronic_diseases][fields][0]": "name",
    "populate[patient_lifestyles][populate]": ["lifestyle,lifestyle_option"],
    "populate[family_histories][fields][0]": "name",
    "populate[family_histories][fields][1]": "relationship",
    "populate[family_histories][fields][2]": "diseaseHistory",
    "populate[surgeries]":"*",
    "populate[patient_insurances]":"*",
  }
  await getData(`/patients/${id}`, params, true )
    .then((response) => {
      const allergies = [];
      const insurances = [];
      const surgeries = [];
      const vaccines = [];
      const chronics = [];
      const patientLifestyles = [];

      response?.data?.attributes?.allergies?.data.forEach((element) => {
        allergies.push({
          label: element?.attributes?.name,
          value: element?.id,
        });
      });

      response?.data?.attributes?.surgeries?.data.forEach((element) => {
        surgeries.push({
          label: element?.attributes?.name,
          value: element?.id,
        });
      });

      response?.data?.attributes?.vaccines?.data.forEach((element) => {
        vaccines.push({
          label: element?.attributes?.name,
          value: element?.id,
        });
      });

      response?.data?.attributes?.chronic_diseases?.data.forEach((element) => {
        chronics.push({
          label: element?.attributes?.name,
          value: element?.id,
        });
      });

      response?.data?.attributes?.patient_lifestyles?.data.forEach((element) => {
        patientLifestyles.push({
          label: element?.attributes?.name,
          value: element?.id,
        });
      });
      setAllergies(allergies)
      setSurgeries(surgeries)
      setVaccines(vaccines);
      setChronicDiseases(chronics);
      setPatientLifeStyle(patientLifestyles);
      setLoadingFlag(false);
    })
    .catch(() => setLoadingFlag(false));
}

const getSavingsValue = async (setSavedValue, setLoadingFlag) => {
  setLoadingFlag(true);
  await getData("/patient/youSaved", null, true)
    .then((response) => {
      setLoadingFlag(false);
      setSavedValue(response?.youSaved);
    })
    .catch(() => setLoadingFlag(false));
};


const GettingService = {
  getAllergies,
  getVaccines,
  getInsurances,
  getLifeStyles,
  getFamilyHistory,
  getSurgeries,
  getChronicDiseases,
  getOrders,
  getAddresses,
  getNotifications,
  getSuppliers,
  getCategories,
  getProductsByCategory,
  getPopularProducts,
  getProductsBySupplier,
  getProfile,
  getSavingsValue
};

export default GettingService;
