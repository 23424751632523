import React, { useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { OfferDetails } from "../OfferDetails/OfferDetails";
import { t } from "i18next";

export const Slides = ({ offers }) => {
  const [offer, setOffer] = useState();
  const [modalOpen, setModalOpen] = useState();
  const buttonStyle = {
    width: "30px",
    background: "none",
    border: "0px",
  };

  const selectOffer = (offer) => {
    setOffer(offer);
    setModalOpen(true);
  };

  const properties = {
    prevArrow: (
      <button style={{ ...buttonStyle }}>
        <img  loading="lazy" src="./img/arrow_back_ios.svg" alt="back-arrow" />
      </button>
    ),
    nextArrow: (
      <button style={{ ...buttonStyle }}>
        <img  loading="lazy" src="./img/arrow_forward_ios.svg" alt="forward-arrow" />
      </button>
    ),
  };

  return (
    <>
      <OfferDetails modalOpen={modalOpen} setModalOpen={setModalOpen} offer={offer} />
      <p className="slogan-text slider-header">{t("our.offers")}</p>
      <Slide
        slidesToScroll={1}
        slidesToShow={1}
        indicators={true}
        {...properties}
      >
        {offers.map((offer, key) => (
          <div className="d-flex flex-wrap m-auto" key={`${key}-offer`}>
            <img  loading="lazy"
              alt="offer"
              className="width100per slider-img pointer"
              onClick={() => selectOffer(offer)}
              src={offer?.attributes?.image?.data[0]?.attributes?.url}
            />
          </div>
        ))}
      </Slide>
    </>
  );
};
