import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import "../BookingModal/style.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useEffect, useRef, useState } from "react";
import { SignInUpButton } from "../SignInUpButton/SignInUpButton";
import { editData, postData } from "../../services/apiService";
import TokenService from "../../services/token.service";
import { FileUploader } from "react-drag-drop-files";
import PostingService from "../../services/posting.service";
import { TextInput } from "../TextInput/TextInput";
import { DateInput } from "../DateInput/DateInput";
import documentTypes from "../../shares/documentTypes";
import { SelectBox } from "../SelectBox/SelectBox";

export const AddReport = ({
  modalOpen,
  setModalOpen,
  AddQuestionToArray,
  item,
  setItem,
  setLoadingFlag,
}) => {
  const { t, i18n } = useTranslation();
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [date, setDate] = useState("");
  const [files, setFiles] = useState(null);

  const handleChange = (submittedFiles) => {
    setFiles(submittedFiles);
  };

  const submit = async () => {
    setLoadingFlag(true);
    let selectedFiles = [];
    const user = TokenService.getUser();

    await PostingService?.uploadFile(files, title).then(async (res) => {
      setLoadingFlag(true);
      res.forEach((element) => {
        selectedFiles.push(element?.id);
      });

      const data = {
        privatePreviousReports: selectedFiles,
        previousReports: selectedFiles,
        date,
        "showMedicalHistory": true,
        type,
      };

      await postData("/consultations", data, true).then((response) => {
        setLoadingFlag(false);
        setModalOpen(false);
        setItem("");
      });
      
    });
  };

  useEffect(() => {
  }, []);
  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(238, 238, 238)",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "67%",
      height: "fit-content",
      maxWidth: "700px",
      top: "3rem",
      margin: "auto",
    },
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };
  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel="Medical Question"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="position-relative mt-3">
        <img
          loading="lazy"
          src="./img/close.svg"
          className="close-img"
          onClick={() => {
            setModalOpen(false);
          }}
        />
        <div className="form-group">
          <label className="add-family-font-style title-label-family">
            {t("documentTitle")}
          </label>
          <TextInput
            placeholder={`${t("addDocumentTitle")}`}
            onChange={setTitle}
            className={"width100per"}
            value={title}
          />
        </div>
        <div className="form-group">
          <label className="add-family-font-style title-label-family">
            {t("consultationDate")}
          </label>
          <DateInput onChange={setDate} className={"width100per"} />
        </div>
        <div className="d-flex flex-column m-auto ">
          <label className="settings-input-label py-2 ">
            {t("documentType")}
          </label>
          <SelectBox
            items={documentTypes}
            placeholder={t("select.documentType")}
            onChange={setType}
            divClass={"email-input select-padding width100per"}
            formClass="border-0 form-select"
            value={type}
          />
        </div>
      </div>
      <FileUploader
        multiple={true}
        handleChange={handleChange}
        name="file"
        classes={"width100per mw-100 mt-2"}
      />
      <SignInUpButton
        state={title && date && type && files?.length ? "default" : "greyed-out"}
        className={"book-button py-4 mt-3 book-btn-modal"}
        name={t("submit")}
        onClick={() => submit()}
      />
    </ReactModal>
  );
};
