const documentTypes = [
  {
    name: "lab",
  },
  {
    name: "pharmacy",
  },
  {
    name: "scan",
  },
  {
    name: "appointment",
  },
];
export default documentTypes;
