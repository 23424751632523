import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SignInUpButton } from "../../components/SignInUpButton";
import { getData } from "../../services/apiService";
import { SeoSlide } from "../../components/SeoSlide/SeoSlide";

export const WebsiteBlogs = ({ setLoadingFlag }) => {
  const { t, i18n } = useTranslation();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setLoadingFlag(true);
    getData("/blogs",{locale: i18n?.language, 'pagination[pageSize]': 100, 'populate[image][fields]':"url" }).then((res) => {
      setBlogs(res?.data);
      setLoadingFlag(false);
    });
  }, [i18n?.language]);

  return (
    <div className="mt-3">
     {
        blogs?.map((blog, key)=>(
        <div class="card blog-card mb-3 m-auto" key={`${key}-blog`}>
            <img  loading="lazy" class="card-img-top" src={blog?.attributes?.image?.data?.attributes?.url} alt="blog image"/>
            <div class="card-body">
                <h5 class="card-title">{blog?.attributes?.title}</h5>
                <div class="card-text" dangerouslySetInnerHTML={{ __html: blog?.attributes?.body }}/>
            </div>
        </div>
        ))
     }
    </div> 
  );
};
