import { useTranslation } from "react-i18next";

export const NotFound = ({}) => {
  const { t, i18n } = useTranslation();

  return (
      <div className="container my-3 error-page">
        <div className="error-box text-lg-center">
          <img  loading="lazy" src="./img/404.png" className="img-fluid width60per" alt="404" />
          <h2 className="lorem-ipsum my-3">{t("PageNotFound")}</h2>
          <a href="/" className="btn btn-primary">
            <i className="feather-home me-2"></i>
            {t("backtohome")}
          </a>
        </div>
      </div>
  );
};
