import React, { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { getData } from "../../services/apiService";
import i18n from "../../i18n";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const responsiveSettings = [
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 500,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
];
const Specialties = ({}) => {
  const [specialitiesArr, setSpecialtiesArr] = useState([]);
  const navigate = useNavigate();
  async function getNeededData() {
    try {
      const response = await getData("/specialities", {
        locale: i18n?.language ? i18n?.language : "en",
        "populate[image]": "*",
      });
      if (response && response.data) {
        setSpecialtiesArr(response.data);
      } else {
        console.error(
          "No data received from /specialities endpoint:",
          response
        );
      }
    } catch (error) {
      console.error("Error fetching /specialities data:", error);
    }
  }

  useEffect(() => {
    getNeededData();
  }, [specialitiesArr?.length, i18n?.language]);

  return (
    <div>
      <p className="slogan-text slider-header">
        {t("available.specializations")}
      </p>
      {specialitiesArr?.length > 1 ? (
        <Slide
          slidesToScroll={1}
          slidesToShow={1}
          indicators={true}
          responsive={responsiveSettings}
          cssClass="width90per"
        >
          {specialitiesArr
            .filter((specialty) => specialty.attributes.image.data !== null)
            .map((item, key) => (
              <div
                className="text-center card mx-4 pointer"
                key={`speciality-${key}`}
              >
                <img  loading="lazy"
                  alt="specialty"
                  className="slider-img card-img-top"
                  onClick={() => {
                    navigate("clinics", {
                      state: {
                        speciality: item?.attributes?.name
                      },
                    });
                  }}
                  src={item?.attributes?.image?.data?.attributes?.url}
                />
                <div className="card-body card-header">
                  <p className="lorem-ipsum">{item?.attributes?.name}</p>
                </div>
              </div>
            ))}
        </Slide>
      ) : (
        ""
      )}
    </div>
  );
};

export default Specialties;
