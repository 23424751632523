import ReactModal from "react-modal";
import "../BookingModal/style.css";
import "react-calendar/dist/Calendar.css";
import { SignInUpButton } from "../SignInUpButton";
import { t } from "i18next";
import { addToCart } from "../../helpers/addToCart";

export const OfferDetails = ({ modalOpen, setModalOpen, offer }) => {
  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(238, 238, 238)",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "50%",
      height: "93vh",
      maxWidth: "700px",
      top: "3rem",
      margin: "auto",
    },
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };

  const addOfferToCart= ()=>{
    setModalOpen(false);
    addToCart(offer?.attributes?.supplier_product?.data?.attributes?.name,
      offer?.attributes?.supplier_product?.data?.attributes?.price,
      offer?.attributes?.supplier_product?.data?.attributes?.name_ar,
      offer?.attributes?.supplier_product?.data?.id,
      offer?.attributes?.supplier_product?.data?.attributes?.supplier?.data?.attributes?.type);
  }

  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel="Medical Question"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="position-relative mt-3">
        <img  loading="lazy"
          src="./img/close.svg"
          className="close-img"
          onClick={() => {
            setModalOpen(false);
          }}
        />
        <div className="lorem-ipsum text-center m-3">{`${offer?.attributes?.name} - ${offer?.attributes?.title}`}</div>
        <img  loading="lazy"
          alt="offer-img"
          className="width100per"
          src={offer?.attributes?.image?.data[0]?.attributes?.url}
        />
        <div className="d-flex">
          <div
            className="clinic total width90per mt-3"
            dangerouslySetInnerHTML={{ __html: offer?.attributes?.description }}
          />
          {offer?.attributes?.supplier_product?.data !== null ? (
            <img  loading="lazy"
              src="./img/Add to Cart.png"
              onClick={() => {
                addOfferToCart();
              }}
              alt="add-to-cart"
              className="add-to-cart-btn mt-3 pointer"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </ReactModal>
  );
};
