import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import "../BookingModal/style.css";
import "react-calendar/dist/Calendar.css";
import Moment from "moment";
import { t } from "i18next";
import { formatter } from "../../helpers/currencyFormatter";
import "./style.css";
import { ConvertToArabicNumbers } from "../../helpers/convertToArabic";
import { useState } from "react";
import DateService from "../../services/date.service";
import { SignInUpButton } from "../SignInUpButton";
import ConsultationService from "../../services/consultation.service";
import { CancelOrderModal } from "../CancelOrderModal/CancelOrderModal";
import { RescheduleModal } from "../RescheduleModal/RescheduleModal";

export const BookingDetails = ({
  modalOpen,
  setModalOpen,
  item,
  setLoadingFlag,
  setVideoConfig,
  setVideoCall,
}) => {
  const { i18n } = useTranslation();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openRescheduleModal, setRescheduleModalOpen] = useState(false);

  Moment.locale(i18n.language);

  function startCall() {
    ConsultationService?.startConsultation(
      item?.attributes?.appointment?.data?.attributes?.consultation?.data?.id,
      setVideoConfig,
      setLoadingFlag
    );
    setVideoCall(true);
    setModalOpen(false);
  }

  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(238, 238, 238)",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "67%",
      height: "fit-content",
      maxWidth: "700px",
      top: "3rem",
      marginRight: "auto",
      marginLeft: "auto",
    },
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };
  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel="Booking"
      style={customStyles}
      ariaHideApp={false}
    >
      <CancelOrderModal
        id={item?.id}
        modalOpen={openCancelModal}
        type={item?.attributes?.type}
        setModalOpen={setOpenCancelModal}
        setLoadingFlag={setLoadingFlag}
      />
      <RescheduleModal
        modalOpen={openRescheduleModal}
        setModalOpen={setRescheduleModalOpen}
        item={item}
        setLoadingFlag={setLoadingFlag}
      />
      <div
        className="position-relative mt-3"
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
      >
        <img  loading="lazy"
          src="./img/close.svg"
          className="close-img"
          onClick={() => {
            setModalOpen(false);
            document.body.style.overflow = "auto";
          }}
        />
        <div className="d-flex gap-3 doctor_nam">
          <p className="m-auto text-center">
            {item?.attributes?.type === "appointment" ? (
              i18n.language === "ar" ? (
                `د.${item?.attributes?.doctor?.data?.attributes?.profile?.data?.attributes?.firstname_ar} ${item?.attributes?.doctor?.data?.attributes?.profile?.data?.attributes?.lastname_ar}`
              ) : (
                `Dr. ${item?.attributes?.doctor?.data?.attributes?.profile?.data?.attributes?.firstname} ${item?.attributes?.doctor?.data?.attributes?.profile?.data?.attributes?.lastname}`
              )
            ) : (
              <b className="dr-hamdy-maged">{`${t("order")}: ${
                i18n.language === "ar"
                  ? ConvertToArabicNumbers(item?.id)
                  : item?.id
              }`}</b>
            )}
          </p>
        </div>
        {item?.attributes?.type === "appointment" ? (
          <div>
            <div
              className="d-flex flex-column fit-content my-2"
              onClick={() => {}}
            >
              <div className="d-flex gap-3 ">
                <img  loading="lazy" src="./img/calendar_today.svg" />
                <p className="mb-0 select_tim">{t("orderdate")}</p>
              </div>
              <div className="d-flex gap-4 ms-5">
                <p className="date">
                  {Moment(
                    item?.attributes?.appointment?.data?.attributes?.timeslot
                      ?.data?.attributes?.start
                  ).format("YYYY/MM/DD")}
                </p>
                {item?.attributes?.appointment?.data?.attributes?.timeslot?.data
                  ?.attributes ? (
                  <div className="pm-700pm">{`${new Date(
                    item?.attributes?.appointment?.data?.attributes?.timeslot?.data?.attributes?.start
                  )?.toLocaleString(`${i18n.language}-EG`, {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })} - ${new Date(
                    item?.attributes?.appointment?.data?.attributes?.timeslot?.data?.attributes?.end
                  )?.toLocaleString(`${i18n.language}-EG`, {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}`}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mb-3">
              {i18n.language === "ar"
                ? item?.attributes?.doctor?.data?.attributes?.noteText_ar
                : item?.attributes?.doctor?.data?.attributes?.noteText}
            </div>
            <div className="d-flex gap-3">
              <div className="select_tim">{`${t("status")}:`}</div>
              <b>{`${t(
                item?.attributes?.appointment?.data?.attributes?.status
              )}`}</b>
            </div>
          </div>
        ) : (
          <div>
            <table
              className="mt-3 width100per"
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              <thead>
                <tr>
                  <th className="p-2">{t("item")}</th>
                  <th className="px-4">{t("price")}</th>
                  <th className="p-2">{t("quantity")}</th>
                </tr>
              </thead>
              <tbody>
                {item?.attributes?.line_items?.data?.map(
                  (cartItem, cartKey) => (
                    <tr key={`${cartKey}-item`}>
                      <td className="item-width clinic p-2">
                        {cartItem?.attributes?.name}
                      </td>
                      <td className="clinic p-2 px-4">
                        {formatter().format(cartItem?.attributes?.unitPrice)}
                      </td>
                      <td className="clinic p-2">
                        {cartItem?.attributes?.quantity}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {/* <div className="d-flex gap-5 order-details-item-head">
              <div className="item-width">Item</div>
              <div className="price-width">Price</div>
              <div>Quantity</div>
            </div>
            {item?.attributes?.line_items?.data?.map((cartItem, cartKey) => (
              <div className="d-flex gap-5 " key={`${cartKey}-cart-item`}>
                <div className="item-width clinic">
                  {cartItem?.attributes?.name}
                </div>
                <div className="clinic">
                  {formatter().format(cartItem?.attributes?.unitPrice)}
                </div>
                <div className="clinic">{cartItem?.attributes?.quantity}</div>
              </div>
            ))} */}
          </div>
        )}

        <div className="d-flex gap-3">
          <div className="select_tim">{`${t("discount")}:`}</div>
          <b>
            {item?.attributes?.discount
              ? `${formatter().format(item?.attributes?.discount)}`
              : 0}
          </b>
        </div>
        <div className="d-flex gap-3">
          <div className="select_tim">{`${t("total")}:`}</div>
          <b>{`${formatter().format(item?.attributes?.totalPrice)}`}</b>
        </div>
        {item?.attributes?.appointment?.data?.attributes?.status ===
        "cancelled" ? (
          <div>
            <div className="d-flex gap-3">
              <div className="select_tim">{`${t("canceledfrom")}:`}</div>
              <b>{`${
                item?.attributes?.appointment?.data?.attributes?.cancelFrom
                  ? item?.attributes?.appointment?.data?.attributes
                      ?.cancelFrom === "PaymentGatewayInterrupted"
                    ? t("PaymentGatewayInterrupted")
                    : t(
                        item?.attributes?.appointment?.data?.attributes
                          ?.cancelFrom
                      )
                  : "-"
              }`}</b>
            </div>
            <div className="d-flex gap-3">
              <div className="select_tim">{`${t("cancelreason")}:`}</div>
              <b>{`${
                item?.attributes?.appointment?.data?.attributes?.cancelReason
                  ?.reason
                  ? item?.attributes?.appointment?.data?.attributes
                      ?.cancelReason?.reason
                  : "-"
              }`}</b>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="d-flex gap-3">
          <div className="select_tim">{`${t("paymentstatus")}:`}</div>
          <b>{`${t(item?.attributes?.paymentStatus)}`}</b>
        </div>
        {item?.attributes?.type === "lab" ? (
          <div className="d-flex gap-3">
            <div className="select_tim">{`${t("location")}: `}</div>
            <b>
              {item?.attributes?.lab_order?.data?.attributes?.location === "lab"
                ? t("atlab")
                : t("athome")}
            </b>
          </div>
        ) : (
          ""
        )}
        {item?.attributes?.type === "scan" ? (
          <div className="d-flex gap-3">
            <div className="select_tim">{`${t("location")}: `}</div>
            <b>
              {item?.attributes?.scan_order?.data?.attributes?.location ===
              "lab"
                ? t("atlab")
                : t("athome")}
            </b>
          </div>
        ) : (
          ""
        )}

        {item?.attributes?.type !== "appointment" && (
          <div className="d-flex gap-3">
            <div className="select_tim">{`${t("status")}: `}</div>
            <b>
              {item?.attributes?.type === "nurse"
                ? t(item?.attributes?.nurse_order?.data?.attributes?.status)
                : ""}
              {item?.attributes?.type === "pharmacy"
                ? t(item?.attributes?.pharmacy_order?.data?.attributes?.status)
                : ""}
              {item?.attributes?.type === "lab"
                ? t(item?.attributes?.lab_order?.data?.attributes?.status)
                : ""}
              {item?.attributes?.type === "scan"
                ? t(item?.attributes?.scan_order?.data?.attributes?.status)
                : ""}
            </b>
          </div>
        )}
        <div className="d-flex gap-3">
          {(item?.attributes?.type === "appointment" &&
            item?.attributes?.appointment?.data?.attributes?.status !==
              "completed" &&
            item?.attributes?.appointment?.data?.attributes?.status !==
              "cancelled" &&
            DateService.calculateTimeDifference(
              null,
              item?.attributes?.appointment?.data?.attributes?.timeslot?.data
                ?.attributes?.start
            )?.minutes) ||
          DateService.calculateTimeDifference(
            null,
            item?.attributes?.appointment?.data?.attributes?.timeslot?.data
              ?.attributes?.start
          )?.hours ||
          (item?.attributes?.type !== "appointment" &&
            (item?.attributes?.type === "nurse"
              ? item?.attributes?.nurse_order?.data?.attributes?.status !==
                  "completed" &&
                item?.attributes?.nurse_order?.data?.attributes?.status !==
                  "cancelled"
              : false || item?.attributes?.type === "pharmacy"
              ? item?.attributes?.pharmacy_order?.data?.attributes?.status !==
                  "completed" &&
                item?.attributes?.pharmacy_order?.data?.attributes?.status !==
                  "cancelled"
              : false || item?.attributes?.type === "lab"
              ? item?.attributes?.lab_order?.data?.attributes?.status !==
                  "completed" &&
                item?.attributes?.lab_order?.data?.attributes?.status !==
                  "cancelled"
              : false || item?.attributes?.type === "scan"
              ? item?.attributes?.scan_order?.data?.attributes?.status !==
                  "completed" &&
                item?.attributes?.scan_order?.data?.attributes?.status !==
                  "cancelled"
              : false)) ? (
            <SignInUpButton
              name={t("cancel")}
              className="btn starts-in-5h mt-3 btn-danger"
              onClick={() => setOpenCancelModal(true)}
            />
          ) : (
            ""
          )}
          {item?.attributes?.type === "appointment" &&
          item?.attributes?.appointment?.data?.attributes?.status !==
            "cancelled" &&
          item?.attributes?.appointment?.data?.attributes?.status !==
            "completed" &&
          (DateService.calculateTimeDifference(
            null,
            item?.attributes?.appointment?.data?.attributes?.timeslot?.data
              ?.attributes?.start
          )?.minutes ||
            DateService.calculateTimeDifference(
              null,
              item?.attributes?.appointment?.data?.attributes?.timeslot?.data
                ?.attributes?.start
            )?.hours) ? (
            <SignInUpButton
              name={t("reschedule")}
              className="booking-card-details-btn btn btn-primary p-0 starts-in-5h mt-3"
              onClick={() => setRescheduleModalOpen(true)}
            />
          ) : (
            ""
          )}
        </div>
        {item?.attributes?.type === "appointment" &&
        item?.attributes?.appointment?.data?.attributes?.doctor_service?.data
          ?.attributes?.serviceType === "telehealth" &&
        item?.attributes?.appointment?.data?.attributes?.consultation?.data
          ?.id &&
        DateService.calculateTimeDifference(
          null,
          item?.attributes?.appointment?.data?.attributes?.timeslot?.data
            ?.attributes?.start
        )?.minutes < 16 ? (
          <SignInUpButton
            name={t("startconference")}
            className="booking-card-details-btn width100per starts-in-5h mt-3"
            onClick={() => startCall()}
          />
        ) : (
          ""
        )}
        {/* {item?.attributes?.paymentStatus === "unpaid" &&
            item.attributes.paymentType === "pre_paid" &&
            item?.attributes?.appointment?.data?.attributes?.status !==
              "cancelled" ? (
              <SignInUpButton
                name={t("completepayment")}
                className={"pay-btn selected-time-slot"}
                onClick={() => {
                  window.location.href = item?.attributes?.payment_url;
                }}
              />
            ) : (
              ""
            )} */}
      </div>
    </ReactModal>
  );
};
