import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import i18n from "../../i18n";

export const SignInUpButton = ({ state, className, name, onClick, img }) => {
  return (
    <button
      onClick={onClick}
      disabled={state === "greyed-out" ? true : false}
      className={`${state} ${className}`}
    >
      <div className="text-wrapper">{name}</div>
      {img ? (
        <img  loading="lazy" className={`arrow-forward-ios-icon ${i18n?.language === 'ar' ? 'flip-img' :''}`} alt="arrow-forward" src={img} />
      ) : (
        ""
      )}
    </button>
  );
};

SignInUpButton.propTypes = {
  state: PropTypes.oneOf(["pressed", "greyed-out", "default"]),
};
