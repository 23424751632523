import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import "../BookingModal/style.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useCallback, useEffect, useRef, useState } from "react";
import Moment from "moment";
import BookingService from "../../services/booking.service";
import { SignInUpButton } from "../SignInUpButton/SignInUpButton";
import "moment/locale/ar";
import { editData } from "../../services/apiService";

export const RescheduleModal = ({
  modalOpen,
  setModalOpen,
  item,
  originalType,
  setLoadingFlag,
}) => {
  var days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const [date, setDate] = useState(new Date(item?.attributes?.appointment?.data?.attributes?.timeslot?.data?.attributes?.start));
  const [timeslot, setTimeSlot] = useState({});
  const [calendarClassName, setCalendarClass] = useState("d-none");
  const [type, setType] = useState(item?.attributes?.appointment?.data?.attributes?.doctor_service?.data?.attributes?.serviceType);
  const [selectedService, setSelectedService] = useState({});
  const [currentTimeSlots, setCurrentTimeSlots] = useState([]);

  Moment.locale(i18n.language);

  function reschedule() {
    const url = window.location.href.split("/");
    setLoadingFlag(true);
    const data = {
      data: {
        status: "rescheduled",
        timeslot: timeslot?.id,
      },
    };

    editData("/orders", item?.id, data).then(()=>{
      window.location.href = `${url[0]}//${url[2]}/dashboard?selected=booking`;
    });
  }

  const selectService = useCallback(
    (service) => {
      const useService = service ? service : selectedService ;
      const dayName = days[date ? date.getDay() : new Date(item?.attributes?.appointment?.data?.attributes?.timeslot?.data?.attributes?.start).getDay()];
      const TimeSlots = useService?.attributes?.working_days?.data
        ?.find((item) => item.attributes?.day === dayName)
        ?.attributes?.timeslots?.data?.filter(
          (time) =>
            Moment(time.attributes.start).format("YYYY/MM/DD") ===
            Moment(date).format("YYYY/MM/DD")
        );
      setCurrentTimeSlots(TimeSlots);
    },
    [date]
  );

  useEffect(() => {
    setDate(new Date(item?.attributes?.appointment?.data?.attributes?.timeslot?.data?.attributes?.start));
    setType(item?.attributes?.appointment?.data?.attributes?.doctor_service?.data?.attributes?.serviceType);
    const handleClickOutside = (event) => {
      if (!event?.target?.className?.includes("react-calendar")) {
        setCalendarClass && setCalendarClass("d-none");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("keyup", function (event) {
      const key = event.key; // Or const {key} = event; in ES6+
      if (key === "Escape") {
        handleClickOutside(event);
      }
    });
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(()=>{
    if (item?.attributes?.doctor?.data?.id) {
      BookingService.getServiceAvailability(
        item?.attributes?.appointment?.data?.attributes?.doctor_service?.data?.id,
        setSelectedService,
        selectService,
        setLoadingFlag
      );
    }
  },[])

  useEffect(()=>{
    selectService();
  },[date])

  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(238, 238, 238)",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "67%",
      height: "fit-content",
      maxWidth: "700px",
      top: "3rem",
      marginRight: "auto",
      marginLeft: "auto",
    },
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };
  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel="Booking"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="position-relative mt-3">
        <img  loading="lazy"
          src="./img/close.svg"
          className="close-img"
          onClick={() => {
            setModalOpen(false);
            setType(originalType);
            document.body.style.overflow = "auto";
          }}
        />
        <div className="d-flex gap-3 doctor_nam">
          <p className="m-auto text-center">
            {i18n.language === "ar"
              ? `د.${item.attributes?.doctor?.data?.attributes?.profile?.data?.attributes?.firstname_ar} ${item.attributes?.doctor?.data?.attributes?.profile?.data?.attributes?.lastname_ar}`
              : `Dr. ${item.attributes?.doctor?.data?.attributes?.profile?.data?.attributes?.firstname} ${item.attributes?.doctor?.data?.attributes?.profile?.data?.attributes?.lastname}`}
          </p>
        </div>
        <div className="mt-2">
          {type === "clinic" &&
          selectedService?.attributes?.clinic_service?.data?.attributes?.clinic
            ?.data?.attributes?.address?.data
            ? selectedService?.attributes?.clinic_service?.data?.attributes
                ?.clinic?.data?.attributes?.address?.data?.attributes?.street
            : ""}
        </div>
        <div
          className="d-flex flex-column fit-content my-2 pointer"
          onClick={() => setCalendarClass("")}
        >
          <div className="d-flex gap-3 ">
            <img  loading="lazy" src="./img/calendar_today.svg" />
            <p className="mb-0 select_tim">{t("selectdate")}</p>
          </div>
          <p className="date">
            {Moment(date)
              .local(i18n.language ? i18n.language : "en")
              .format("YYYY/MM/DD")}
          </p>
        </div>
        <Calendar
          onChange={(e) => {
            setDate(e);
            setCalendarClass("d-none");
          }}
          value={date}
          minDate={new Date()}
          className={calendarClassName}
          ref={ref}
        />
        <div className="d-flex gap-3 fit-content mb-2">
          <img  loading="lazy" src="./img/access_time.svg" />
          <p className="mb-0 select_tim">{t("selecttimeslot")}</p>
        </div>
        <div className="d-flex flex-wrap time-slots-container">
          {currentTimeSlots?.map((time, key) => (
            <div
              className={`d-flex gap-3 time-slot ${
                time.id === timeslot.id ? "selected-time-slot" : ""
              }`}
              key={`time-slot-${key}`}
              onClick={() => setTimeSlot(time)}
            >
              {new Date(time?.attributes?.start)?.toLocaleString(
                `${i18n.language}-EG`,
                {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }
              )}
            </div>
          ))}
        </div>
      </div>
      <SignInUpButton
        state={
          Object.keys(selectedService).length === 0 ||
          Object.keys(timeslot).length === 0
            ? "greyed-out"
            : "default"
        }
        className={"book-button py-4 mt-3 book-btn-modal"}
        name={t("doctor.book")}
        onClick={() => reschedule()}
      />
    </ReactModal>
  );
};
