import { t } from "i18next";
import "./style.css";
import i18n from "../../i18n";
import { ConvertToArabicNumbers } from "../../helpers/convertToArabic";
import PostingService from "../../services/posting.service";
import { useEffect } from "react";
import { useState } from "react";
import { editData } from "../../services/apiService";
import { toast } from "react-toastify";

const UserDetailsLayout = ({ user, addresses }) => {
  const [image, setImage] = useState(null);
  const changeImage = (e) => {
    setImage(e.target.files ? URL.createObjectURL(e.target.files[0]): image)
    if(e.target.files){
      PostingService?.uploadFile(e.target.files[0]).then((res)=>{
        if(res?.length > 0){
           editData("/users", user?.id, {
          "profilePicture": res[0]?.id
        }).then(() => {
          toast.success(t("prof-pic-update.success"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        })
        }
       
      });
    }
    
  };

  useEffect(()=>{
    setImage(user?.profiles && user?.profiles[0].profilePicture?.url
      ? user?.profiles[0].profilePicture?.url
      : "./img/11390281.png");
  },[user])

  return (
    <div className="patient-details-layout">
      <div className="doctors-info">
        <div className="nameratingpic">
          <div className="wrapper-image-placeholder">
            <img  loading="lazy"
              className="image-placeholder-icon-personal-card"
              alt="personal-card"
              src={
                image
                  ? image
                  : "./img/11390281.png"
              }
            />
            <label for="profileImage">
              <img  loading="lazy"
                src="./img/upload-img.png"
                className="upload-img-icon pointer"
              />
            </label>
            <input
              className="form-control opacity-0 position-absolute"
              id="profileImage"
              type="file"
              onChange={(e) => changeImage(e)}
            />
          </div>
          <div className="namerating">
            <div className="nametitle">
              <h2 className="john-doe">
                {user?.profiles
                  ? `${user?.profiles[0]?.firstname} ${user?.profiles[0]?.lastname}`
                  : ""}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="booking-details">
        <div className="booking-info-frame-parent">
          <div className="booking-info-frame">
            <h2 className="booking-details1">{t("profile.details")}</h2>
          </div>
        </div>
        <div className="appointment-details">
          <div className="session-time-parent">
            <div className="session-time">
              <div className="session-time1">{t("phonenumber")}</div>
              <div className="" dir="ltr">
                {user ? ConvertToArabicNumbers(user?.mobileNumber) : "-"}
              </div>
            </div>
            <div className="separator" />
            <div className="booked-on">
              <div className="booked-on1">{t("address")}</div>
              <div className="d-flex gap-1">
                <div className="th-of-may">
                  {addresses?.find((address) => address.default === true)
                    ?.street ||
                    (addresses.length ? `${addresses[0]?.street}` : "-")}
                </div>
                <div>{`${
                  addresses?.length
                    ? ", " +
                      addresses[0]?.cities?.find(
                        (cit) => cit.locale === i18n.language
                      )?.name
                    : ""
                }`}</div>
              </div>
            </div>
            <div className="separator1" />
            <div className="appointment-type">
              <div className="appointment-type1">{t("loyality.points")}</div>
              <div className="type">
                <div className="examination1">
                  {user?.profiles ? user?.profiles[0]?.loyaltyPoints : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsLayout;
