import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Moment from "moment";
import { deleteData, getData, postData } from "../../services/apiService";
import { TextInput } from "../TextInput";
import { toast } from "react-toastify";
import { checkExtension } from "../../helpers/checkExtension";

export const MedicalQuestions = ({
  detailedQuestion,
  setDetailedQuestion,
  medicalQuestions,
  setMedicalQuestions,
  setLoadingFlag,
}) => {
  const { i18n, t } = useTranslation();
  const [replies, setReplies] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  function deleteQuestion(id) {
    deleteData("/medical-questions", id).then((response) => {
      if (response) {
        const arr = medicalQuestions.filter((q) => q.id !== id);
        setMedicalQuestions(arr);
      }
    });
  }

  function reply() {
    setLoadingFlag(true);
    const data = {
      type: "patient",
      reply: newMessage,
      medical_question: detailedQuestion?.id,
    };

    if (newMessage) {
      postData("/medical-question-replies", { data }, true).then((response) => {
        setNewMessage("");
        setLoadingFlag(false);
        replies.push(response?.data);
        if (response) {
          toast.success(t("reply.success"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
    } else {
      toast.error(t("writemessagefirst"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  useEffect(() => {
    setLoadingFlag(true);
    const params = {
      "filters[$and][0][medical_question][id][$eq]": detailedQuestion?.id,
    };
    getData("/medical-question-replies", params, true).then((res) => {
      setReplies(res?.data);
      setLoadingFlag(false);
    });
  }, [detailedQuestion?.id]);

  return (
    <div
      className={`page-dashboard-container ${
        detailedQuestion?.id ? "scrolled-down" : ""
      }`}
    >
      <div className="questions-container px-5">
        {Object.keys(detailedQuestion)?.length === 0 &&
          medicalQuestions.map((item, key) => (
            <div
              className="question-box width90per"
              key={`question-box-${key}`}
            >
              <div className="input-field-dashboard">
                <div className="even-though-i">{item?.attributes.explain}</div>
              </div>
              <div className="actionsdate">
                <div className="datetime">
                  <div className="pm-thu-6123">
                    {new Date(item?.attributes?.createdAt)?.toLocaleString(
                      `${i18n.language}-EG`,
                      {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    ) +
                      Moment(item?.attributes?.createdAt).format(
                        " ddd DD/MM/YY"
                      )}
                  </div>
                </div>
                <div
                  className={`actions m${
                    i18n?.language === "ar" ? "e" : "s"
                  }-auto`}
                >
                  {item?.attributes?.answered ? (
                    <div
                      className="response-indicator2 pointer"
                      onClick={() => setDetailedQuestion(item)}
                    >
                      <img  loading="lazy"
                        className="check-circle-icon"
                        alt=""
                        src="./img/check-circle@2x.png"
                      />
                      <div className="hi-john-doe answered-p">
                        {t("answered")}
                      </div>
                      <img  loading="lazy"
                        className="check-circle-icon"
                        alt=""
                        src="./img/keyboard-arrow-right@2x.png"
                      />
                    </div>
                  ) : (
                    <div className="deledit">
                      <div
                        className="delete pointer"
                        onClick={() => deleteQuestion(item?.id)}
                      >
                        <img  loading="lazy"
                          className="delete-icon"
                          alt=""
                          src="./img/delete@2x.png"
                        />
                        <div className="hi-john-doe">{t("delete")}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        {Object.keys(detailedQuestion)?.length > 0 && (
          <>
            <div className="question-box-parent width100per">
              <div className="question-box">
                <div className="input-field-dashboard-div width100per text-start">
                  <div className="even-though-i">
                    {detailedQuestion?.attributes?.explain}
                  </div>
                  <div className="pm-thu-6123">
                    {new Date(
                      detailedQuestion?.attributes?.createdAt
                    )?.toLocaleString(`${i18n.language}-EG`, {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }) +
                      Moment(detailedQuestion?.attributes?.createdAt).format(
                        " ddd DD/MM/YY"
                      )}
                  </div>
                  <div>
                    {detailedQuestion?.attributes?.attachments?.data?.map(
                      (item, key) =>(
                        checkExtension(item?.attributes?.url)?.toLowerCase() ===
                        "pdf" ? 
                          <a href={`${item?.attributes?.url}`}  key={key} target='_blank'>
                            <img  loading="lazy"
                              className="medical-question-attachment"
                              src="./img/pdf_136522.png"
                              alt="PDF"
                            />
                          </a>
                        : <a href={`${item?.attributes?.url}`}  key={key} target='_blank'>
                         <img  loading="lazy"
                            src={item?.attributes?.url}
                            className="medical-question-attachment"
                            key={key}
                            alt="Attachment"
                          />
                          </a>
                    ))}
                  </div>
                </div>
              </div>
              {replies?.map((item, key) =>
                item?.attributes?.type === "patient" ? (
                  <div className="question-box" key={`${key}-question`}>
                    <div className="input-field-dashboard-div width100per text-start">
                      <div className="even-though-i">
                        {item?.attributes?.reply}
                      </div>
                      <div className="pm-thu-6123">
                        {new Date(item?.attributes?.createdAt)?.toLocaleString(
                          `${i18n.language}-EG`,
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        ) +
                          Moment(item?.attributes?.createdAt).format(
                            " ddd DD/MM/YY"
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`doctor-iconname-parent m${
                      i18n?.language === "ar" ? "e" : "s"
                    }-auto`}
                    key={`${key}-answer`}
                  >
                    <div className="response-box width100per">
                      <div className="input-field1 text-start width100per">
                        <div className="hello-john-there-container">
                          <p className="family-medicine">
                            {item?.attributes?.reply}
                          </p>
                        </div>
                        <div className="pm-thu-6123">
                          {new Date(
                            item?.attributes?.updatedAt
                          )?.toLocaleString(`${i18n.language}-EG`, {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }) +
                            Moment(item?.attributes?.updatedAt).format(
                              " ddd DD/MM/YY"
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="position-relative width100per">
              {Object.keys(detailedQuestion)?.length > 0 ? (
                <img  loading="lazy"
                  className="arrow-back-icon-dash mb-3 me-auto pointer"
                  alt="img-arrow"
                  src="./img/arrow-back@2x.png"
                  onClick={() => setDetailedQuestion({})}
                />
              ) : (
                ""
              )}
              <TextInput
                property1="empty"
                className="text-input width100per search-input"
                placeholder={t("writereply")}
                onChange={setNewMessage}
                divClass="width100per"
                value={newMessage}
              />
              <img  loading="lazy"
                src="./img/send-icon.png"
                onClick={() => reply()}
                className="position-absolute send-icon pointer"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
