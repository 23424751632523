import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SignInUpButton } from "../../components/SignInUpButton";
import { getData } from "../../services/apiService";
import { SeoSlide } from "../../components/SeoSlide/SeoSlide";
import { SeoForm } from "./SeoForm";
import ReactPaginate from "react-paginate";

export const SeoTable = ({ setLoadingFlag }) => {
  const { t } = useTranslation();
  const [seoPages, setSeoPages] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [item, setItem] = useState({});
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [result, setResult] = useState([]);

  useEffect(() => {
    setLoadingFlag(true);
    getData("/meta-tags",{
      "pagination[page]": offset,
      "pagination[pageSize]": limit
    }, null, true).then((res) => {
      setSeoPages(res?.data);
      setLoadingFlag(false);
      setResult(res?.meta?.pagination);
    });
  }, [offset]);

  return (
    <div className="d-flex">
      <SeoSlide />
      <SeoForm
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        item={item}
        setItem={setItem}
        setLoadingFlag={setLoadingFlag}
      />
      <div className="width100per d-flex flex-column">
        <div>
          <SignInUpButton
            name="Add Meta tags"
            className="btn starts-in-5h mt-3 btn-primary"
            onClick={() => {
              setModalOpen(true);
            }}
          />
        </div>
        <table className="mt-3 width100per ">
          <thead className="">
            <tr className="head-family-table">
              <th className="p-2">Screen</th>
              <th className="px-4">Title</th>
              <th className="p-2">Description</th>
              <th className="p-2"></th>
            </tr>
          </thead>
          <tbody>
            {seoPages?.map((item, key) => (
              <tr key={`${key}-item`}>
                <td className="item-width clinic p-2">
                  {item?.attributes?.slug}
                </td>
                <td className="clinic p-2 px-4">{item?.attributes?.title}</td>
                <td className="clinic p-2">{item?.attributes?.descrption}</td>
                <td className="clinic p-2">
                  <img  loading="lazy"
                    onClick={() => {
                      setItem(item);
                      setModalOpen(true);
                    }}
                    src="./img/edit.png"
                    className="width25px mx-2 pointer"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel={t("pagination.next")}
            onPageChange={(e) => {
              setOffset(e.selected + 1);
            }}
            pageRangeDisplayed={limit}
            pageCount={Math.ceil(result?.total / limit)}
            previousLabel={t("pagination.prev")}
            renderOnZeroPageCount={null}
            disabledClassName="greyed-out"
          />
        </div>
      </div>
    </div>
  );
};
