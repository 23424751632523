import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../TextInput";
import { SignInUpButton } from "../SignInUpButton";
import { editData } from "../../services/apiService";
import TokenService from "../../services/token.service";
import { useNavigate } from "react-router-dom";

export const DeleteAccount = ({}) => {
  const { i18n, t } = useTranslation();
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
  }, []);

  function confirmDelete(){
    editData('/users', TokenService?.getUser()?.user_id, {
      "deleted": true
    }).then(()=>{
      navigate('/logout')
    }).catch((err)=>{
      console.log(err);
      setError(err?.response?.data?.error?.message ? err?.response?.data?.error?.message : 'Something went wrong, please try again');
    })
  }

  return (
    <>
      <TextInput
        placeholder={`${t("write delete to confirm")}`}
        onChange={setConfirmText}
        value={confirmText}
        className={"mx-3 mt-3"}
      />
      <p className="text-danger width-fit">{error ? `* ${error}` : ''}</p>
      <SignInUpButton
        state={ ( i18n?.language === 'en' && confirmText !== 'delete' ) || ( i18n?.language === 'ar' && confirmText !== 'امسح' ) ? "greyed-out" : "default"}
        className={"m-3 sign-in-up-button"}
        name={t("confirmDelete")}
        onClick={() => confirmDelete()}
      />
    </>
  );
};
