import React from "react";
import "./style.css";
import { t } from "i18next";
import { MetaTags } from "../../components/MetaTags/MetaTags";

export const DoctorRegistration = ({ setLoadingFlag }) => {
  
  return (
    <>
      <MetaTags
        title={`${t("doxx")} - ${t("auth.doctor")}`}
        description={t("thank.register.doc")}
      />
      <div className="pharmacy doctor-registeration-div">
        <div className="success-p">{t("hello.doctor")}</div>
        <div className="success-p">{t("thank.register.doc")}</div>
        <div>
          <div className="for-doctors-items1 ">
            <div className="social-media1 m-auto">
              <a
                href="https://play.google.com/store/apps/details?id=com.openner.doxx_doctor&pcampaignid=web_share"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="submit-a-medical-container">
                  <img  loading="lazy"
                    src="./img/google-play-badge.png"
                    alt="google-play-link"
                  />
                </div>
              </a>
              <a
                href="https://apps.apple.com/eg/app/doxx-doctor/id1579515020"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="submit-a-medical-container">
                  <img  loading="lazy"
                    src="./img/Download_on_the_App_Store_Badge.png"
                    alt="app-store-link"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="success-p">{t("contact.register.doc")}</div>
        <div className="">
          <a href={`https://wa.me/+201288883699`}>
            <img  loading="lazy"
              src="./img/whatsapp.png"
              alt="whatsapp"
              className="whatsapp-icon"
            />
          </a>
        </div>
      </div>
    </>
  );
};
