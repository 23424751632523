import React, { useState } from "react";
import { TextInput } from "../TextInput";
import { useTranslation } from "react-i18next";
import { MultipleSelect } from "../MultipleSelect/MultipleSelect";
import { SignInUpButton } from "../SignInUpButton";
import PostingService from "../../services/posting.service";

export const SelectMultiItems = ({
  title,
  items,
  selectedItems,
  addToItems,
  onChange,
  postUrl,
  setItems,
  setLoadingFlag,
  className
}) => {
  const [mode, setMode] = useState("select");
  const [name, setName] = useState("");
  const { t } = useTranslation();

  async function addItem() {
    await PostingService.addItem(postUrl,name,setLoadingFlag).then((res)=>addToItems(res, items, setItems));
    setMode("select");
  }

  return (
    <div className={`d-flex flex-column ${className}`}>
      <label className="settings-input-label py-2 text-start">{title}</label>
      {mode === "select" ? (
        <div className="d-flex">
          <MultipleSelect
            options={items}
            selected={selectedItems}
            setSelected={onChange}
            className={'width100per'}
            divClass={'width100per'}
          />
          {/* <div
            className={`new-question pointer multi-add-img mx-2`}
            onClick={() => setMode("add")}
          >
            <img  loading="lazy"
              className="add-circle-outline-icon multi-add-img"
              alt="add-circle"
              src="./img/add-circle-outline@2x.png"
            />
          </div> */}
        </div>
      ) : (
        <div className="d-flex">
          <TextInput
            className="email-input "
            placeholder={t("dashboard.enternewitem")}
            onChange={setName}
            value={name}
          />
          <div className="position-relative align-self-center ms-2">
            <SignInUpButton
              className="book-button"
              name={t("add")}
              onClick={addItem}
            />
            <img  loading="lazy" src="./img/close.svg" alt="close" className="close-icon-multi" onClick={()=>setMode("select")}/>
          </div>
        </div>
      )}
    </div>
  );
};
