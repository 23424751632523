import React, { useEffect, useState } from "react";
import { Categories } from "../../components/Categories/Categories";

import { PharmacySlider } from "../../components/PharmacySlider/PharmacySlider";
import "./style.css";
import GettingService from "../../services/getting.service";
import { PharmaCard } from "../../components/PharmaCard/PharmaCard";
import { t } from "i18next";
import { MetaTags } from "../../components/MetaTags/MetaTags";

const Pharmacy = ({ setLoadingFlag }) => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [content, setContent] = useState('');

  async function getNeededData() {
    await GettingService.getCategories(
      setCategories,
      "pharmacy",
      null,
      setLoadingFlag
    );
    await GettingService.getPopularProducts(setProducts, setLoadingFlag);
  }

  useEffect(() => {
    getNeededData();
  }, []);

  return (<>
  <MetaTags type="pharma page" setContent={setContent} title={`${t("doxx")} - ${t("header.pharmacy")}`} description={`${t("order.in.seconds")} ${t("submit.your.order")}`}/>
   <div className="pharmacy">
      <div className="div-5">
        <div className="main-container">
          <PharmacySlider
            className="pharmacy-slider-instance"
            httpsLottiefilesClassName="pharmacy-slider-3"
            httpsLottiefilesClassNameOverride="pharmacy-slider-4"
            overlapGroupClassName="pharmacy-slider-2"
            property1="left"
          />
          <div className="medicine-categories">
            <div className="text-wrapper-19">{t("browse.categories")}</div>
            <Categories
              categories={categories}
              arrowMovementCircleStyleOverrideClassName="categories-instance"
              overlapGroupClassName="categories-placeholder"
              property1="one"
            />
          </div>
          <div className="popular-products px-5 width100per">
            <div className="popular-products-2 width100per">
              <div className="text-wrapper-19 mt-5">{t("popular.products")}</div>
              <div className="d-flex gap-4 pharmacy-cards">
                {products?.map((item, key) => (
                  <PharmaCard key={`popular-card-${key}`} name={item?.productname} price={item?.price} name_ar={item?.productname} id={item?.supplierProductId} items={cartItems} />
                ))}
              </div>
            </div>
          </div>
          <div className="for-less-than-container mt-3 text-center">
            <div className="for-less-than data-metatags black-text">
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default Pharmacy;