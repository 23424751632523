import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import "../BookingModal/style.css";
import "react-calendar/dist/Calendar.css";
import { SignInUpButton } from "../SignInUpButton/SignInUpButton";
import { TextInput } from "../TextInput";
import { PhoneNumberInput } from "../PhoneNumberInput";
import { confirmAlert } from "react-confirm-alert";
import { useEffect, useState } from "react";
import { SelectMultiItems } from "../SelectMultiItems/SelectMultiItems";
import { SelectBox } from "../SelectBox/SelectBox";
import { DateInput } from "../DateInput/DateInput";
import TokenService from "../../services/token.service";
import { editData, postData } from "../../services/apiService";
import familyRelationships from "../../shares/familyRelationships";

export const AddMemberModal = ({
  chronicDiseases,
  modalOpen,
  setModalOpen,
  addNewMember,
  editMember,
  currentItem,
  setLoadingFlag
}) => {
  const { t } = useTranslation();
  const [gender, setGender] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [relationShip, setRelationShip] = useState();
  const [birthDate, setBirthDate] = useState();
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [errors, setErrors] = useState([]);
  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      background: "#ffffff",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "67%",
      height: "fit-content",
      maxWidth: "700px",
      top: "3rem",
      margin: "auto",
      zIndex: 1
    },
    overlay: {
      position: "fixed",
      zIndex: 3,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };

  function addFamilyMember(){
    const data = {
      "name": name,
      "relationship":relationShip.toLowerCase(),
      "phone": phone,
      "diseases": selectedDiseases.map(function (e) {
        return e.value;
      }),
      "gender": gender.toLowerCase(),
      "birthdate": birthDate.toString(),
      "patient": TokenService.getUser()?.patient_id,
    }
    if(currentItem?.value){
      editData('/family-histories', currentItem?.value, {data}).then((res)=>{
        editMember(res?.data);
        setModalOpen(false);
        setName('');
        setRelationShip('');
        setPhone('');
        setBirthDate('');
        setGender('');
        setSelectedDiseases([]);
      })
    }else{
      postData('/family-histories', {data},true).then((res)=>{
        addNewMember(res?.data);
        setModalOpen(false);
        setName('');
        setRelationShip('');
        setPhone('');
        setBirthDate('');
        setGender('');
        setSelectedDiseases([]);
      })
    }
    
  }

  function submit(){
    confirmAlert({
      title: "",
      message:
        t("add.familymember.msg"),
      buttons: [
        {
          label: t("proceed"),
          onClick: () => {
            addFamilyMember();
          },
        },
        {
          label: t("cancel"),
          onClick: () => {},
        },
      ],
    });
  }

  useEffect(()=>{
    if(currentItem?.value){
      setName(currentItem?.label);
      setRelationShip(currentItem?.relationship);
      setPhone(currentItem?.phone);
      setBirthDate(currentItem?.birthdate);
      setGender(currentItem?.gender);
      setSelectedDiseases(currentItem?.diseases?.map(function (e) {
        return {value : e.id, label: e?.attributes?.name};
      }));
    }else{
      setModalOpen(false);
      setName('');
      setRelationShip('');
      setPhone('');
      setBirthDate('');
      setGender('');
      setSelectedDiseases([]);
    }
  },[currentItem])

  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel="AddingFamilyMember"
      style={customStyles}
      ariaHideApp={false}
    >
       <img  loading="lazy"
          src="./img/close.svg"
          className="family-member-close-img"
          onClick={() => {
            setModalOpen(false);
            setName('');
            setRelationShip('');
            setPhone('');
            setBirthDate('');
            setGender('');
            setSelectedDiseases([]);
          }}
        />
      <div className="add-family-font-style add-family-head">
        {currentItem?.value ?  t("editfamilymember") : t("addfamilymember")}
      </div>
      <div className="d-flex flex-column m-auto ">
        <label className="settings-input-label py-2 ">{t("name")}</label>
        <TextInput
          placeholder={`${t("enter.name")}`}
          onChange={setName}
          value={name}
          className={"width100per"}
        />
      </div>
      <div className="d-flex flex-column m-auto ">
        <label className="settings-input-label py-2 ">
          {t("relationship")}
        </label>
         <SelectBox
          items={familyRelationships}
          placeholder={t("select.relationship")}
          onChange={setRelationShip}
          divClass={"email-input select-padding width100per"}
          formClass="border-0 form-select"
          value={relationShip}
        />
      </div>
      <div className="d-flex flex-column m-auto ">
        <label className="settings-input-label py-2 ">{t("phone")}</label>
        <PhoneNumberInput
          className="email-input-instance width100per"
          property1="empty"
          noMargin={true}
          onChange={setPhone}
          error={errors?.phone}
          defaultValue={phone}
          placeholder={t("auth.enterphone")}
        />
      </div>
      <div className="d-flex flex-column m-auto width100per">
        <SelectMultiItems
          title={t("dashboard.chronicdiseases")}
          items={chronicDiseases}
          selectedItems={selectedDiseases}
          onChange={setSelectedDiseases}
          postUrl={"/chronic-diseases"}
          setItems={setSelectedDiseases}
          className={'width100per'}
          setLoadingFlag={setLoadingFlag}
        />
      </div>
      <div className="d-flex flex-column m-auto">
        <label className="settings-input-label py-2 ">{t("gender")}</label>
        <SelectBox
          items={[
            {
              name: "Male",
            },
            { name: "Female" },
          ]}
          placeholder={t("select.gender")}
          onChange={setGender}
          divClass={"email-input select-padding width100per"}
          formClass="border-0 form-select"
          value={gender}
        />
      </div>
      <div className="d-flex flex-column m-auto">
        <label className="settings-input-label py-2 ">{t("birthdate")}</label>
        <DateInput
          property1="empty"
          placeholder={t("selectdate")}
          onChange={setBirthDate}
          error={errors?.date}
          divClass={"email-input select-padding width100per"}
          value={birthDate}
          formClass="border-0 form-select width100per"
          className="width100per"
          maxDate={new Date()?.toISOString()?.split("T")[0]}
        />
      </div>
      <SignInUpButton
        state={
            !name || !phone || !birthDate || !gender || !relationShip 
            ? "greyed-out"
            : "default"
        }
        className={"add-member-btn"}
        name={t("submit")}
        onClick={() => submit()}
      />
    </ReactModal>
  );
};
