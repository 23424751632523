import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const EmailInput = ({ property1, className, placeholder, onChange ,error, value, noMargin }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "empty",
  });

  return (
    <div className={!noMargin?"mb-3":""}>
      <input
        type="email"
        className={`email-input ${state.property1} ${className}`}
        placeholder={placeholder}
        onClick={() => {
          dispatch("click");
        }}
        onChange={(e)=>onChange(e?.target?.value)}
        defaultValue={value}
      />
      <p className="text-danger  h6 position-absolute  text-start">{error}</p>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "click":
      return {
        ...state,
        property1: "email",
      };
    default:
      break;
  }

  return state;
}

EmailInput.propTypes = {
  property1: PropTypes.oneOf(["empty", "email"]),
};
