import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/themes/gray.min.css";
import FroalaEditorComponent from "react-froala-wysiwyg";

export const TextEditor = ({ content, onChange }) => {
  const handleModelChange = (newContent) => {
    onChange(newContent);
  };

  return (
    <div>
      <FroalaEditorComponent
        tag="textarea"
        model={content}
        onModelChange={handleModelChange}
        config={{
          placeholderText: "Edit Your Content Here!",
          charCounterCount: true,
          toolbarButtons: [
            "bold",
            "italic",
            "underline",
            "strikeThrough",
            "paragraphFormat",
            "alert",
          ],
          theme: "gray", // Make sure to import the theme CSS file
        }}
      />
    </div>
  );
};
