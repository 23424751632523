import React, { useEffect, useState } from "react";
import { SignInUpButton } from "../../components/SignInUpButton";
import ReactModal from "react-modal";
import { TextInput } from "../../components/TextInput";
import { SelectBox } from "../../components/SelectBox/SelectBox";
import SeoScreens from "../../shares/SeoScreens";
import { editData, postData } from "../../services/apiService";

export const SeoForm = ({ setLoadingFlag, modalOpen, setModalOpen, item, setItem }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState('');
    const [hOne, setHOne] = useState('');
    const [hTwo, setHTwo] = useState('');
    const [screen, setScreen] = useState();
    const [content, setContent] = useState();
    const [pageContent, setPageContent] = useState();
    const [type, setType] = useState('en');

    const customStyles = {
        content: {
          position: "absolute",
          border: "1px solid rgb(204, 204, 204)",
          background: "#ffffff",
          overflow: "auto",
          borderRadius: "10px",
          outline: "none",
          padding: "20px",
          width: "67%",
          height: "fit-content",
          maxWidth: "700px",
          top: "3rem",
          margin: "auto",
          zIndex: 1
        },
        overlay: {
          position: "fixed",
          zIndex: 3,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          background: "rgba(255, 255, 255, 0.75)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
        },
      };

    useEffect(()=>{
      setTitle(item?.attributes?.title);
      setDescription(item?.attributes?.descrption);
      setTags(item?.attributes?.tags);
      setHOne(item?.attributes?.h1);
      setHTwo(item?.attributes?.h2);
      setScreen(item?.attributes?.slug);
      setContent(item?.attributes?.content);
      setType(item?.attributes?.type);
      setPageContent(item?.attributes?.data);
    },[item?.id]);

    function submit(){
      setLoadingFlag(true);
      const data = {
        title,
        descrption: description,
        tags,
        h1: hOne,
        h2: hTwo,
        slug: screen,
        content,
        data:pageContent,
        type
      }

      if(item?.id){
        editData('/meta-tags', item?.id ,{data}, true).then((res)=>{
          setModalOpen(false);
          setItem();
          window.location.reload();
        })
      }else{
        postData('/meta-tags', {data}, true, null, true).then((res)=>{
          setModalOpen(false);
          setItem();
          window.location.reload();
        })
      }
     
    }

  return (
    <ReactModal
    isOpen={modalOpen}
    contentLabel="AddingMetaTags"
    style={customStyles}
    ariaHideApp={false}
  >
     <img  loading="lazy"
        src="./img/close.svg"
        className="family-member-close-img"
        onClick={() => {
          setModalOpen(false);
          setItem();
        }}
      />
    <div className="add-family-font-style add-family-head">
      Add meta tags
    </div>
    <div>
      <label className="add-family-font-style title-label-family">
        Language
      </label>
      <SelectBox
        items={[
          {
            name: "ar",
          },
          {
            name: "en",
          }
        ]}
        placeholder="Select the screen"
        onChange={setType}
        divClass={"email-input select-padding width100per"}
        formClass="border-0 form-select"
        value={type}
      />
    </div>
    <div>
      <label className="add-family-font-style title-label-family">
        Screen
      </label>
      <SelectBox
        items={SeoScreens}
        placeholder="Select the screen"
        onChange={setScreen}
        divClass={"email-input select-padding width100per"}
        formClass="border-0 form-select"
        value={screen}
      />
    </div>
    <div>
      <label className="add-family-font-style title-label-family">
        Title
      </label>
      <TextInput
        placeholder="Enter screen title"
        onChange={setTitle}
        className={'width100per'}
        value={title}
      />
    </div>
    <div>
      <label className="add-family-font-style title-label-family">
       Description
      </label>
      <TextInput
        placeholder="Enter screen description"
        onChange={setDescription}
        className={'width100per'}
        value={description}
      />
    </div>
    <div>
      <label className="add-family-font-style title-label-family">
       Tags
      </label>
      <TextInput
        placeholder="Enter screen tags"
        onChange={setTags}
        className={'width100per'}
        value={tags}
      />
    </div>
    <div>
      <label className="add-family-font-style title-label-family">
       H1
      </label>
      <TextInput
        placeholder="Enter h1"
        onChange={setHOne}
        className={'width100per'}
        value={hOne}
      />
    </div> 
    <div>
      <label className="add-family-font-style title-label-family">
       H2
      </label>
      <TextInput
        placeholder="Enter h2"
        onChange={setHTwo}
        className={'width100per'}
        value={hTwo}
      />
    </div>
    <div>
      <label className="add-family-font-style title-label-family">
       Content
      </label>
      <TextInput
        placeholder="Enter content"
        onChange={setContent}
        className={'width100per'}
        value={content}
      />
    </div>
    <div>
      <label className="add-family-font-style title-label-family">
       Page Content
      </label>
      <textarea
        placeholder="Enter screen description"
        onChange={(e)=>setPageContent(e?.target?.value)}
        className={'width100per'}
        value={pageContent}
      />
    </div>

    <SignInUpButton
      state={
        !screen || (!hOne && !hTwo && !title && !description && !content)
          ? "greyed-out"
          : "default"
      }
      className={"add-member-btn"}
      name="Submit"
      onClick={() => submit()}
    />
  </ReactModal>
  );
};
