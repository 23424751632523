import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import "../BookingModal/style.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useEffect, useRef, useState } from "react";
import { SignInUpButton } from "../SignInUpButton/SignInUpButton";
import { editData, postData } from "../../services/apiService";
import "./style.css";
import TokenService from "../../services/token.service";
import { FileUploader } from "react-drag-drop-files";
import PostingService from "../../services/posting.service";

export const AddQuestion = ({
  modalOpen,
  setModalOpen,
  AddQuestionToArray,
  questionItem,
  setQuestionItem,
  setLoadingFlag,
}) => {
  const { t, i18n } = useTranslation();
  const [question, setQuestion] = useState("");

  const [files, setFiles] = useState(null);
  const handleChange = (submittedFiles) => {
    setFiles(submittedFiles);
  };

  const submitQuestion = async () => {
    setLoadingFlag(true);
    let selectedFiles = [];
    const user = TokenService.getUser();

    await PostingService?.uploadFile(files).then(async (res) => {
      setLoadingFlag(true);
      res.forEach((element) => {
        selectedFiles.push(element?.id);
      });

      const data = {
        patient: user?.patient_id,
        explain: question,
        attachments: selectedFiles,
      };

      if (questionItem?.id) {
        await editData("/medical-questions", questionItem?.id, { data }).then(
          (response) => {
            setLoadingFlag(false);
            setModalOpen(false);
            AddQuestionToArray(response, true);
            setQuestionItem("");
          }
        );
      } else {
        await postData("/medical-questions", data, true).then((response) => {
          setLoadingFlag(false);
          setModalOpen(false);
          AddQuestionToArray(response);
          setQuestionItem("");
        });
      }
      
    });
  };

  useEffect(() => {
    setLoadingFlag(true);
    if (questionItem?.id) {
      setQuestion(questionItem?.attributes?.explain);
    }
    setLoadingFlag(false);
  },[modalOpen]);
  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(238, 238, 238)",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "67%",
      height: "fit-content",
      maxWidth: "700px",
      top: "3rem",
      margin: "auto",
    },
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };
  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel="Medical Question"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="position-relative mt-3">
        <img  loading="lazy"
          src="./img/close.svg"
          className="close-img"
          onClick={() => {
            setModalOpen(false);
          }}
        />
        <div className="form-group">
          <textarea
            className="form-control question-textarea"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder={t("enterquestion.placeholder")}
            onChange={(e) => setQuestion(e.target.value)}
            defaultValue={question}
          ></textarea>
        </div>
      </div>
      <FileUploader
        multiple={true}
        handleChange={handleChange}
        name="file"
        classes={"width100per mw-100 mt-2"}
      />
      <SignInUpButton
        state={question === "" ? "greyed-out" : "default"}
        className={"book-button py-4 mt-3 book-btn-modal"}
        name={t("question.send")}
        onClick={() => submitQuestion()}
      />
    </ReactModal>
  );
};
