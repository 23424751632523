import { t } from "i18next";
import { TextInput } from "../TextInput";
import "./style.css";
import { SelectBox } from "../SelectBox/SelectBox";
import { CheckBox } from "../CheckBox/CheckBox";
import { useCallback, useEffect, useState } from "react";
import { getData } from "../../services/apiService";
import i18n from "../../i18n";
import GettingService from "../../services/getting.service";

export const FilterSearch = ({
  setLoadingFlag,
  setSearchName,
  setSelectedSpecialty,
  setSelectedRegion,
  setGender,
  searchName,
  selectedSpecialty,
  selectedRegion,
  setSupplierId,
  selectedSupplierId,
  setCategoryId,
  selectedCategoryId,
  selectedGrade,
  setSelectedGrade,
  gender,
  search,
  type,
  suppliers,
  total,
}) => {
  const [specialties, setSpecialties] = useState([]);
  const [doctorTitles, setDoctorTitles] = useState([]);
  const [regions, setRegions] = useState([]);
  const [categories, setCategories] = useState([]);

  const getSpecialties = useCallback(async () => {
    await getData("/specialities/doctors/count", {
      "filters[locale][$eq]": localStorage.getItem("lang") ? localStorage.getItem("lang") : "en" ,
    })
      .then((response) => {
        setSpecialties(response);
      })
      .catch((error) => {});
  }, [setSpecialties]);

  const getDoctorTitles = useCallback(async () => {
    await getData("/doctor-titles")
      .then((response) => {
        setDoctorTitles(response?.data);
      })
      .catch((error) => {});
  }, [setDoctorTitles]);

  const getRegions = useCallback(async () => {
    await getData("/cities", {
      locale: localStorage.getItem("lang"),
    })
      .then((response) => {
        setRegions(response.data);
      })
      .catch((error) => {});
  }, [setRegions]);

  useEffect(() => {
    if (type !== "scan-lab" && type !== "nurse" && type !== "pharmacy") {
      getRegions();
      getSpecialties();
      getDoctorTitles();
    } else if (type === "pharmacy") {
      GettingService.getCategories(
        setCategories,
        "pharmacy",
        null,
        setLoadingFlag
      );
    }
  }, [getRegions, getSpecialties, getDoctorTitles, i18n.language]);

  function clearFilter() {
    if (type !== "scan-lab" && type !== "nurse" && type !== "pharmacy") {
      setSelectedRegion("");
      setSelectedSpecialty("");
      setGender("");
    } else if (type === "pharmacy") {
      setCategoryId("");
    } else {
      setSupplierId("");
    }

    setSearchName("");
  }
  return (
    <div className="filter-search-container">
      <div className="match-found d-flex gap-2">
        <div className="cost ">{`${t("search.matchfound")}`}</div>
        <div className="cost ">{`${total?.toLocaleString(
          i18n.language === "ar" ? "ar-u-nu-arab" : "en"
        )} `}</div>
      </div>
      <div className="search-filter">
        <div className="d-flex m-auto flex-column width100per">
          <div className="filter-title">
            <div className="clear">{t("search.searchfilter")}</div>
          </div>
          <div className="search-fields mt-4">
            <TextInput
              property1="empty"
              className="text-input width100per"
              placeholder={t("landing.searchname")}
              onChange={setSearchName}
              divClass="width100per mb-2"
              value={searchName}
            />
            {type !== "scan-lab" && type !== "nurse" && type !== "pharmacy" && (
              <>
                <SelectBox
                  placeholder={t("landing.searchspecialty")}
                  items={specialties ? specialties : []}
                  type="specialty"
                  onChange={setSelectedSpecialty}
                  divClass={"mb-2 width100per"}
                  formClass={"width100per form-select"}
                  value={selectedSpecialty}
                />
                <SelectBox
                  placeholder={t("landing.searchregion")}
                  items={regions ? regions : []}
                  type="region"
                  onChange={setSelectedRegion}
                  divClass={"mb-2 width100per"}
                  formClass={"width100per form-select"}
                  value={selectedRegion}
                />
                 <SelectBox
                  placeholder={t("landing.searchgrade")}
                  items={doctorTitles}
                  type="doctorgrade"
                  onChange={setSelectedGrade}
                  divClass={"mb-2 width100per"}
                  formClass={"width100per form-select"}
                  value={selectedGrade}
                />
                <div className="specialist-gender flex-wrap">
                  <div className="specialist-gender1">
                    <div className="cost mt-2">
                      {t("search.specialistgender")}
                    </div>
                  </div>
                  <div className="genders mt-3">
                    <CheckBox
                      label={t("search.male")}
                      onChange={setGender}
                      selected={gender}
                      value={"male"}
                    />
                    <CheckBox
                      label={t("search.female")}
                      onChange={setGender}
                      selected={gender}
                      value="female"
                    />
                  </div>
                </div>

              </>
            )}
            {type === "scan-lab" && (
              <SelectBox
                placeholder={t("suppliers")}
                items={suppliers ? suppliers : []}
                type="supplier"
                onChange={setSupplierId}
                divClass={"mb-2 width100per"}
                formClass={"width100per form-select"}
                value={selectedSupplierId}
                valueIsId={true}
              />
            )}
            {type === "pharmacy" && (
              <SelectBox
                placeholder={t("categories")}
                items={categories ? categories : []}
                type="supplier"
                onChange={setCategoryId}
                divClass={"mb-2 width100per"}
                formClass={"width100per form-select"}
                value={selectedCategoryId}
                valueIsId={true}
                disablePlaceholder={true}
              />
            )}
          </div>

          <div className="action-buttons mt-3">
            <div className="search-button-filter" onClick={() => search()}>
              <img  loading="lazy" className="image-icon" alt="" src="/img/search@2x.png" />
              <div className="clear">{t("header.search")}</div>
            </div>
            <div className="clear-button" onClick={() => clearFilter()}>
              <img  loading="lazy" className="image-icon" alt="" src="/img/clear@2x.png" />
              <div className="clear">{t("search.clear")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
