import { useEffect } from "react";
import "react-slideshow-image/dist/styles.css";

export const DashboardMenuItem = ({
  title,
  imgSrc,
  type,
  activeTab,
  onClick,
  dashboardTitle,
  setDashboardTitle,
  activeImg,
}) => {

  return (
    <div
      className={`${
        activeTab === type ? "overview" : "booking-activity"
      } pointer`}
      onClick={() => {setDashboardTitle(dashboardTitle); onClick(type)}}
    >
      <img  loading="lazy"
        className="preview-icon"
        alt=""
        src={activeTab === type ? activeImg : imgSrc}
      />
      <div className="dr-hamdy-maged">{title}</div>
    </div>
  );
};
