import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import TokenService from "../../services/token.service";

const PusherEvent = () => {
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const {t} = useTranslation();

  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "67%",
      height: "fit-content",
      maxWidth: "700px",
      top: "3rem",
      margin: "auto",
    },
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };

  useEffect(() => {
    Pusher.logToConsole = true;

    // Initialize Pusher
    const pusher = new Pusher("167da08dbc7273f2ce7a", {
      cluster: "mt1",
    });

    // Subscribe to the channel
    const channel = pusher.subscribe("WEB");

    // Bind to any event to check if other events are received
    channel.bind_global((eventName, data) => {
      if ( (data?.data?.title || data?.data?.imageUrl ) && data?.data?.user_id === TokenService?.getUser()?.user_id  ) {
        setModalOpen(true);
      }

      setMessage(i18n?.language === 'ar' && data?.data?.title_ar ? data?.data?.title_ar : data?.data?.title);
      setImage(data?.data?.imageUrl);
      
    });

    // Cleanup function to unsubscribe and disconnect on unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, []);

  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel=""
      style={customStyles}
      ariaHideApp={false}
    >
      <img  loading="lazy"
        src="./img/close.svg"
        onClick={() => {
          setModalOpen(false);
        }}
        className="float-right"
      />
       <h3 className="pusher-text mb-2 pusher-header">
        <img  loading="lazy" src="./img/warning-500.png" className="width25px mx-2"/>
        {t('importantMessage')}</h3>
        {image ? <img  loading="lazy" src={"./img/image-placeholder.png"} alt="" className="mb-3 d-block mx-auto" /> : ''} 
      <h5 className="pusher-text">{message}</h5>

    </ReactModal>
  );
};

export default PusherEvent;
