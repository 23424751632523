import { useTranslation } from "react-i18next";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";

export const SeoSlide = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Sidebar>
      <Menu>
        <div className="title2">
          <img  loading="lazy"
            className="title-child seo-logo"
            alt=""
            src="./img/frame-23@2x.png"
          />
        </div>
        <MenuItem
          onClick={() => {
            navigate("/seo-screens");
          }}
        >
          <b>Screens Meta tags</b>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/seo-blogs");
          }}
        >
          <b>Blogs</b>
        </MenuItem>
        <MenuItem className="logout-seo">
          <img  loading="lazy" alt="" src="./img/power_settings_new.svg" />
          <div
            className="dr-hamdy-maged pointer"
            onClick={() => {
              AuthService.logout(true);
              navigate("/seo-login");
            }}
          >
            {t("logout")}
          </div>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};
