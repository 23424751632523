import { useTranslation } from "react-i18next";
import "./style.css";
import { AddOrMinusCart } from "../AddOrMinusCart/AddOrMinusCart";
import { formatter } from "../../helpers/currencyFormatter";
import { getPharmaImage } from "../../helpers/getPharmaImage";

export const CartCardItem = ({ item, type }) => {
  const { i18n, t } = useTranslation();
  return (
    <div className="product-detailsplaceholder-parent d-flex mb-3 flex-wrap">
      <div className="product-detailsplaceholder align-self-center">
        {type === "pharmacy" && (
          <div className="product-image-placeholder">
            <img  loading="lazy"
              className="medicine-1-icon"
              alt="cart-img"
              src={getPharmaImage(item?.name)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "./img/image-placeholder.png";
              }}
            />
          </div>
        )}
        <div className="product-details align-self-center">
          <b className="product-name-placeholder">
            {i18n.language === "ar" && item?.name_ar
              ? item?.name_ar
              : item?.name}
          </b>
        </div>
        <div className="price my-auto pb-4">
          <div className="total">{t("price")}</div>
          <div className="egp">{`${formatter().format(item.price)}`}</div>
        </div>
        <div
          className={`mt-4 m${
            i18n.language === "ar" ? "e" : "s"
          }-auto minus-add-div`}
        >
          <AddOrMinusCart item={item} type={type} className="my-auto width100per" />
        </div>
      </div>
    </div>
  );
};
