import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Moment from "moment";
import { deleteData, getData, postData } from "../../services/apiService";
import { TextInput } from "../TextInput";
import { toast } from "react-toastify";
import { checkExtension } from "../../helpers/checkExtension";
import { AddReport } from "../AddReport/AddReport";

export const MedicalReports = ({
  reports
}) => {
  const { i18n, t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div
      className={`page-dashboard-container`}
    >
      <AddReport modalOpen={modalOpen}/>
      <div className="questions-container px-5">
        {reports.map((item, key) => (
            <div
              className="question-box width90per"
              key={`question-box-${key}`}
            >
              <div className="input-field-dashboard">
                <div className="even-though-i">{item?.attributes?.explain}</div>
              </div>
              <div className="actionsdate">
                <div className="datetime">
                  <div className="pm-thu-6123">
                    {new Date(item?.attributes?.date)?.toLocaleString(
                      `${i18n.language}-EG`,
                      {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    ) +
                      Moment(item?.attributes?.date).format(
                        " ddd DD/MM/YY"
                      )}
                  </div>
                </div>
                <div
                  className={`actions m${
                    i18n?.language === "ar" ? "e" : "s"
                  }-auto`}
                >
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
